import * as React from 'react';
import {useState} from 'react';
import {Box, Stack} from "@mui/material";
import OptionalFeeListItem from "@/components/Fees/OptionalFeeListItem.tsx";
import {Fee} from "@/types/Fee.ts";
import CategorySelect from "@/components/Fees/CategorySelect.tsx";
import SearchFees from "@/components/Fees/SearchFees.tsx";

type Props = {
    optionalFees: Fee[];
};

const groupFeesByCategory = (filteredFees: Fee[]) => {
    return filteredFees.reduce((acc, fee) => {
        if (!acc[fee.feeCategory]) {
            acc[fee.feeCategory] = [];
        }

        acc[fee.feeCategory].push(fee);

        return acc;
    }, {} as Record<string, Fee[]>);
};

const OptionalFeeList = ({optionalFees} : Props) : React.ReactElement => {
    const [searchFeesValue, setSearchFeesValue] = React.useState('');
    const [feeCategory, setFeeCategory] = useState<string>('');

    const filteredFees = optionalFees
        ?.filter(fee => feeCategory === '' || fee.feeCategory === feeCategory)
        ?.filter(fee => searchFeesValue === '' || fee.name.toLowerCase().includes(searchFeesValue.toLowerCase()))
        ?.sort((a, b) => a.feeCategory.localeCompare(b.feeCategory));
    const feesByCategory = groupFeesByCategory(filteredFees);

    return <Stack
        sx={{
            flexDirection: 'column',
            width: '100%',
            height: '100%',
        }}
    >
        <Stack
            sx={{
                flexDirection: {xs: 'column', lg: 'row'},
                mb: 3,
                pt: 1,
            }}
        >
            <CategorySelect
                feeCategory={feeCategory}
                setFeeCategory={setFeeCategory}
                feeCategories={
                    Array.from(new Set(optionalFees?.map(fee => fee.feeCategory)))
                        .sort((a, b) => a.localeCompare(b))
                }
            />
            <SearchFees searchFeesValue={searchFeesValue} setSearchFeesValue={setSearchFeesValue}/>
        </Stack>
        <Stack
            sx={{
                flexGrow: 1,
                mb: 2,
                overflow: 'auto',
            }}
        >
            {Object.keys(feesByCategory).map(category => {
                return <Stack
                    key={`category-${category}`}
                    sx={{
                        flexDirection: 'column',
                    }}
                >
                    {category !== '' && <Box
                        sx={{
                            fontWeight: 800,
                            width: '100%',
                            borderBottom: '1px solid black',
                            textAlign: 'left',
                            my: 1,
                            pb: '4px',
                        }}
                    >
                        {category}
                    </Box>}
                    {feesByCategory[category].map(fee => {
                        return <OptionalFeeListItem
                            key={`fee-${fee.id}`}
                            fee={fee}
                        />
                    })}
                </Stack>
            })}
        </Stack>
    </Stack>
}

export default OptionalFeeList;
