import {FeeRequest} from "@/types/FeeRequest.ts";
import ModalBase from "@/components/Modal/ModalBase.tsx";
import {ModalBody} from "react-bootstrap";
import {Typography} from "@mui/material";

const StatusHistoryModal = ({show, handleClose, feeRequest} : {show: boolean, handleClose: () => void, feeRequest: FeeRequest | null}) => {
    return <ModalBase
        show={show}
        title={'Fee Request Status History'}
        handleClose={handleClose}
    >
        <ModalBody>
            {feeRequest && feeRequest.approverNotes
                ? <Typography variant={'body1'} style={{display: 'inline', whiteSpace: 'pre-line'}}>
                    {feeRequest.approverNotes.replace(/\r/g, '\n')}
                </Typography>
                : <Typography variant={'body1'} sx={{fontStyle: 'italic'}}>No status history to display.</Typography>}
        </ModalBody>
    </ModalBase>
}

export default StatusHistoryModal;
