import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import React, {useContext, useMemo, useState} from 'react';
import {FeeProposalRequestSchemaType} from "@/components/Forms/FeeProposalRequestForm.tsx";
import {IconButton, List, ListItem, ListItemText, Stack, TextField, Typography} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {useFieldArray, UseFormReturn} from "react-hook-form";
import {Employee} from "@/types/Employee.ts";
import {jwtInstructorContext} from "@/components/Providers/JWTProvider.tsx";

export type Props = {
    form : UseFormReturn<FeeProposalRequestSchemaType>;
    sponsors: Employee[];
    selectedSponsors: Employee[];
};

const SponsorList =(
    {
        form,
        sponsors,
        selectedSponsors,
    } : Props
) : React.ReactElement => {
    const {user} = useContext(jwtInstructorContext);
    const [sponsorFilter, setSponsorFilter] = useState('');
    const {control, formState, watch} = form;
    const {fields, append, remove} = useFieldArray({control, name: 'sponsors'});
    const watchFieldArray = watch("sponsors");
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index]
        };
    });

    const filteredSponsors = useMemo(() => {
        if (sponsorFilter.length < 1) {
            return [];
        }

        return sponsors
            .filter(sponsor => {
                const idFilter = sponsor.id.toLowerCase().includes(sponsorFilter.toLowerCase());
                const nameFilter = sponsor.name.toLowerCase().includes(sponsorFilter.toLowerCase());
                const alreadyInList = selectedSponsors.find(s => s.id === sponsor.id);

                if (alreadyInList) {
                    return false;
                }

                return idFilter || nameFilter;
            });
    }, [sponsors, sponsorFilter, selectedSponsors]);

    return <Stack
        sx={{
            flexDirection: 'column',
            border: '1px solid #444',
            borderRadius: '8px',
            p: 1,
        }}
    >
        <Stack
            sx={{
                alignItems: 'center',
                width: '100%',
            }}
        >
            <Typography variant='h6'>Sponsor List</Typography>
            {Boolean(formState.errors.sponsors) && <Typography
                variant='body1'
                sx={{
                    color: '#d32f2f',
                }}
            >
                {formState.errors.sponsors?.message}
            </Typography>}
        </Stack>

        <Stack
            sx={{
                flexDirection: {xs: 'column', sm: 'row'},
                justifyContent: {xs: 'flex-beginning', sm: 'space-between'},
                width: '100%',
                gap: 1,
            }}
        >
            <Stack
                sx={{
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    width: '100%',
                }}
            >
                <Stack
                    sx={{
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Typography variant='body1'>Add Fee Sponsor</Typography>
                </Stack>

                <TextField
                    fullWidth
                    name='searchFeeSponsor'
                    placeholder='Fee Sponsor Filter'
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setSponsorFilter(event.target.value);
                    }}
                    value={sponsorFilter}
                    size="small"
                    sx={{
                        backgroundColor: '#fff',
                    }}
                />
                <List dense={true} sx={{p: 0}}>
                    {filteredSponsors
                        .map(sponsor => {
                            return <ListItem
                                key={`add-sponsor-${sponsor.id}`}
                                secondaryAction={
                                    <IconButton
                                        edge="end"
                                        aria-label="add"
                                        onClick={() => {
                                            console.info('append', {
                                                id: sponsor.id,
                                                name: sponsor.name,
                                            });

                                            append({
                                                id: sponsor.id,
                                                name: sponsor.name,
                                            });
                                        }}
                                    >
                                        <AddCircleIcon/>
                                    </IconButton>
                                }
                                sx={{
                                    backgroundColor: '#fff',
                                    mt: 1,
                                }}
                            >
                                <ListItemText
                                    primary={`${sponsor.id} - ${sponsor.name}`}
                                />
                            </ListItem>
                        })}
                </List>
            </Stack>
            <Stack
                sx={{
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <Stack
                    sx={{
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Typography variant='body1'>Fee Sponsors</Typography>
                </Stack>

                <List dense={true} sx={{p: 0}}>
                    {controlledFields.map((sponsor, index) => {
                        return <ListItem
                            key={`remove-sponsor-${sponsor.id}`}
                            secondaryAction={
                                sponsor.id !== user?.employeeID  && <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => {
                                        remove(index);
                                    }}
                                >
                                    <RemoveCircleIcon/>
                                </IconButton>
                            }
                            sx={{
                                backgroundColor: '#fff',
                                mb: 1,
                            }}
                        >
                            <ListItemText
                                primary={`${sponsor.id} - ${sponsor.name}`}
                            />
                        </ListItem>
                    })}
                </List>
            </Stack>
        </Stack>
    </Stack>
};

export default SponsorList;
