import React, {ReactNode, useContext, useEffect, useState} from 'react';
import {Fee} from "@/types/Fee.ts";
import {AssessedFee} from "@/types/AssessedFee.ts";
import useGetFees from "@/hooks/useGetFees.ts";
import useGetStudentFees from "@/hooks/useGetStudentFees.ts";
import {jwtContext} from "@/components/Providers/JWTProvider.tsx";
import useGetPurchaseHistory from "@/hooks/useGetPurchaseHistory.ts";

export type PurchaseHistory = {
    fees: Record<string, number>;
    partialStudentFeeIdsAndAmountRemaining: Record<string, number>;
}
export type FeesState = {
    assessedFees: AssessedFee[] | undefined
    optionalFees: Fee[] | undefined
    refreshFees: () => Promise<void>,
    purchaseHistory: PurchaseHistory | undefined
    refreshPurchaseHistory: () => Promise<void>,
}

type Props = {
    children ?: ReactNode;
};

export const FeesProviderContext = React.createContext<FeesState>(
    {
        assessedFees: undefined,
        optionalFees: undefined,
        refreshFees: async () => {},
        purchaseHistory: undefined,
        refreshPurchaseHistory: async () => {},
    }
);

const FeeProvider : React.FC<Props> = ({children} : Props) => {
    const user = useContext(jwtContext);

    const [optionalFees, setOptionalFees] = useState<Fee[] | undefined>();
    const [assessedFees, setAssessedFees] = useState<AssessedFee[] | undefined>();
    const [purchaseHistory, setPurchaseHistory] = useState<PurchaseHistory | undefined>();

    const getFees = useGetFees();
    const getStudentFees = useGetStudentFees();
    const getPurchaseHistory = useGetPurchaseHistory();

    const refreshFees = async () => {
        await doGetStudentFees();
        await doGetFees();
    }

    const doGetFees = async () => {
        const response = await getFees();

        if (response && response.ok) {
            const {fees} = await response.json() as { fees: Fee[] };
            setOptionalFees(fees);
        }
    }

    const refreshPurchaseHistory = async () => {
        const response = await getPurchaseHistory();

        if (response && response.ok) {
            const purchaseHistory = await response.json() as PurchaseHistory;
            setPurchaseHistory(purchaseHistory);
        }
    }

    const doGetStudentFees = async () => {
        const response = await getStudentFees();

        if (response && response.ok) {
            const {studentFees} = await response.json() as { studentFees: AssessedFee[] };
            setAssessedFees(studentFees);
        }
    }

    useEffect(() => {
        if (!assessedFees && user?.userType === 'student') {
            void doGetStudentFees();
        }

        if (!optionalFees && user?.userType === 'student') {
            void doGetFees();
        }

        if (!purchaseHistory && user?.userType === 'student') {
            void refreshPurchaseHistory();
        }
    }, []);

    const value : FeesState = {
        optionalFees,
        assessedFees,
        refreshFees,
        purchaseHistory,
        refreshPurchaseHistory
    }

    return (
        <FeesProviderContext.Provider value={value}>
            {children}
        </FeesProviderContext.Provider>
    );
};

export const useFeeProvider = () : FeesState => {
    return useContext(FeesProviderContext);
};

export default FeeProvider;

