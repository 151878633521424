import {Dispatch, SetStateAction, useMemo} from 'react';
import {MaterialReactTable, type MRT_ColumnDef, useMaterialReactTable,} from 'material-react-table';
import {FeeRequest} from "@/types/FeeRequest.ts";
import {Box, IconButton, Tooltip} from "@mui/material";
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import {DateTimeFormatter} from "@js-joda/core";

type Props = {
    feeRequests: FeeRequest[];
    setShowForm: Dispatch<SetStateAction<boolean>>;
    setSelectedFeeRequest: Dispatch<SetStateAction<FeeRequest | null>>;
    setShowStatusHistoryModal: Dispatch<SetStateAction<boolean>>;
};

const FeeProposals = ({feeRequests, setShowForm, setSelectedFeeRequest, setShowStatusHistoryModal} : Props) => {
    const columns = useMemo<MRT_ColumnDef<FeeRequest>[]>(
        () => [
            {
                accessorKey: 'feeName',
                header: 'Name',
            },
            {
                accessorKey: 'school.shortName',
                header: 'School',
            },
            {
                accessorKey: 'schoolYear.year',
                header: 'Year',
            },
            {
                accessorKey: 'feeType',
                header: 'Type',
            },
            {
                accessorFn: (originalRow) => originalRow.createdAt.format(DateTimeFormatter.ofPattern('M-d-yyyy')),
                accessorKey: 'createdAt',
                header: 'Submission Date',
            },
            {
                accessorKey: 'requestStatus',
                header: 'Status',
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns: columns,
        data: feeRequests,
        enableRowActions: true,
        positionActionsColumn: 'last',
        displayColumnDefOptions: {
            'mrt-row-actions': {
                header: 'Status History',
            }
        },
        renderRowActions: (row) => (
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                <Tooltip title={'Status History'}><IconButton
                    color="primary"
                    onClick={() => {
                        setSelectedFeeRequest(row.row.original);
                        setShowStatusHistoryModal(true);
                    }}
                >
                    <StickyNote2Icon />
                </IconButton></Tooltip>
                {row.row.original.requestStatus === 'Denied' && <Tooltip title={'Resubmit'}><IconButton
                    color="primary"
                    onClick={() => {
                        setShowForm(true);
                        setSelectedFeeRequest(row.row.original);
                    }}
                >
                    <SyncProblemIcon />
                </IconButton></Tooltip>}
            </Box>
        ),
    });

    return <>
        <MaterialReactTable table={table}/>
    </>;
};

export default FeeProposals;
