import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import {TextField} from "@mui/material";

type Props = {
    searchFeesValue: string;
    setSearchFeesValue: (searchFeesValue: string) => void;
}
const SearchFees = ({searchFeesValue, setSearchFeesValue} : Props) : React.ReactElement => {

    return (
        <FormControl
            sx={{
                width: {xs: '100%', lg: '50%'},
            }}
            size="small"
        >
            <TextField
                label="Search Fees"
                variant="outlined"
                size="small"
                name='searchFees'
                value={searchFeesValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchFeesValue(event.target.value);
                }}
            />
        </FormControl>
    );
}

export default SearchFees;
