import {Container, Row} from "react-bootstrap";
import React from "react";

type Props = {
    children: React.ReactNode;
}

const MainDisplayContainer = ({children} : Props) => (
    <>
        <Container>
            <Row className="program-selected">
            </Row>
        </Container>
        <Container className="px-0" style={{minHeight: '70vh'}}>
            {children}
        </Container>
    </>
);

export default MainDisplayContainer;
