import {Button} from "@mui/material";
import type {ButtonProps} from "@mui/material/Button/Button";

const PrimaryButton = (props : ButtonProps) => {
    return <Button
        variant="contained"
        {...props}
        sx={{
            backgroundColor: '#631d79',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: "#F0F0F0",
                color: '#631d79',
            },
            whiteSpace: 'nowrap',
            px: 1,
            width: '100%',
            ...props.sx
        }}
    >{props.children}</Button>
}

export default PrimaryButton;
