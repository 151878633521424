import {Box, Link, Paper, Typography} from '@mui/material';
import {UserType} from "@/types/auth.ts";
import PrimaryButton from "@/components/PrimaryButton.tsx";

interface UserTypeSelectionProps {
  onSelect: (type: UserType) => void;
  onSkipToLogin: () => void;
}

export const UserTypeSelection = ({ onSelect, onSkipToLogin }: UserTypeSelectionProps) => {
  const options: { value: UserType; label: string }[] = [
    { value: 'student', label: "I am a Current Glenbrook Student or Parent" },
    { value: 'community', label: 'I am a Community Member' },
    { value: 'faculty', label: 'I am a Current Glenbrook Staff Member' },
    { value: 'guest', label: 'I am a Guest' },
  ];

  return (
    <Paper elevation={3} sx={{ p: 4, maxWidth: 400, width: '100%' }}>
      <Typography variant="h5" component="h2" gutterBottom align="center">
          Program Registration and Fee Payment System
      </Typography>
      <Typography variant="subtitle1" gutterBottom align="center">
          Please Select One of the Options Below
      </Typography>
      <Box display="flex" flexDirection="column" gap={2} mt={3}>
        {options.map((option) => (
          <PrimaryButton
            key={option.value}
            fullWidth
            onClick={() => onSelect(option.value)}
            sx={{ py: 1.5 }}
          >
            {option.label}
          </PrimaryButton>
        ))}
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Link
            component="button"
            variant="body2"
            onClick={onSkipToLogin}
            sx={{
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            Skip questionnaire and go directly to login
          </Link>
        </Box>
      </Box>
    </Paper>
  );
};
