import {apiEndpoint, ApiFetch} from "@/components/Providers/JWTProvider.tsx";
import {FeeRequest, RawFeeRequest} from "@/types/FeeRequest.ts";
import {ZonedDateTime} from "@js-joda/core";

export const doGetFeeProposals = async (apiFetch: ApiFetch) => {
    const url = new URL(`/v1/instructor/fee-proposals`, apiEndpoint);
    const response = await apiFetch(url.toString());
    const rawFeeProposals = await response.json() as RawFeeRequest[];
    return rawFeeProposals.map((rawFeeProposal) => {
        return mapRawToFeeRequest(rawFeeProposal);
    });
}


const mapRawToFeeRequest = (raw : RawFeeRequest) : FeeRequest => {
    return {
        ...raw,
        createdAt: ZonedDateTime.parse(raw.createdAt),
        saleStartDate: ZonedDateTime.parse(raw.saleStartDate),
        saleEndDate: ZonedDateTime.parse(raw.saleEndDate),
    }
}
