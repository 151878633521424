import * as React from 'react';
import {AssessedFee} from "@/types/AssessedFee.ts";
import AssessedFeeListItem from "@/components/Fees/AssessedFeeListItem.tsx";
import {Box, Button, Stack} from "@mui/material";
import {useMemo} from "react";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";
import {Fee} from "@/types/Fee.ts";
import {apiEndpoint, jwtContext} from "@/components/Providers/JWTProvider.tsx";
import {useFeeProvider} from "@/components/Providers/FeesProvider.tsx";

type Props = {
    assessedFees: AssessedFee[];
};

const AssessedFeeList = ({assessedFees} : Props) : React.ReactElement => {
    const user = React.useContext(jwtContext);
    const {cart, refreshCart} = useCartContext();
    const {refreshFees} = useFeeProvider();

    const orderedAssessedFees = useMemo(() => {
        return assessedFees?.sort((a, b) => {
            const nameA = a.fee.feeCategory.toUpperCase();
            const nameB = b.fee.feeCategory.toUpperCase();
            if (nameA < nameB) {
                return 1;
            }
            if (nameA > nameB) {
                return -1;
            }

            return 0;
        })
    }, [assessedFees]);

    const handleAddToCart = async (fee: Fee, includeCourseQuestions = true) => {
        const url = new URL('/v1/fees/add-to-cart', apiEndpoint);

        let init : RequestInit = {
            method: 'PUT',
            signal: AbortSignal.timeout(10000)
        };

        init.body = JSON.stringify({
            feeId: fee.id,
            courseQuestions: includeCourseQuestions ? fee.courseQuestions : undefined,
        });

        const response = await user?.apiFetch(url.toString(), init);

        if (response && response.ok) {
            await refreshFees();
            await refreshCart();
        } else {
            console.error('An unknown error has occurred.', await response?.text());
        }
    }

    const allFeesInCart = useMemo(() => {
        return assessedFees.every(assessedFee => {
            return cart?.cartItems.some(item => item.itemType === 'fee' && item.assessedFeeId === assessedFee.id) ?? false
        });
    }, [assessedFees, cart?.cartItems])

    const addAllFeesToCart = async () => {
        for (const assessedFee of assessedFees) {
            if (
                assessedFee.status === 'due'
                && !cart?.cartItems.some(item => item.itemType === 'fee' && item.assessedFeeId === assessedFee.id)
            ) {
                await handleAddToCart(assessedFee.fee);
            }
        }
    }

    return <Box
        sx={{
            py: 2,
            mb: 2,
            overflowY: 'scroll',
        }}
    >
        <Stack
            sx={{
                flexDirection: 'column',
                overflowY: 'scroll'
            }}
        >
            {orderedAssessedFees?.map((studentFee) => {
                return <AssessedFeeListItem
                    key={`student-fee-${studentFee.id}`}
                    assessedFee={studentFee}
                    handleAddToCart={handleAddToCart}
                />
            })}
        </Stack>
        <Stack
            sx={{
                flexDirection: 'row',
                justifyContent: 'center',
            }}
        >
            <Button
                variant="contained"
                onClick={addAllFeesToCart}
                disabled={allFeesInCart}
            >
                Add All Fees to Cart
            </Button>
        </Stack>
    </Box>
}

export default AssessedFeeList;
