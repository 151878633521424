import BlockIcon from '@mui/icons-material/Block';
import * as React from 'react';
import {Button, Tooltip} from "@mui/material";
import {AssessedFee} from "@/types/AssessedFee.ts";
import CartWithCheckbox from "@/components/Cart/CartWithCheckbox.tsx";
import {Fee} from "@/types/Fee.ts";
import PaidIcon from "@mui/icons-material/Paid";
import PartialPaid from "@/components/Cart/PartialPaid.tsx";
import {IconDisplayOptions} from "@/types/IconDisplayOptions.ts";

type Props = {
    displayIcon: IconDisplayOptions;
    handleFeeQuestionsModal: () => Promise<void>;
    assessedFee: AssessedFee;
    handleAddToCart: (fee: Fee, includeCourseQuestions?: boolean) => Promise<void>;
};

const AssessedFeeCartIcon = (
    {
        displayIcon,
        handleFeeQuestionsModal,
        assessedFee,
        handleAddToCart
    } : Props
) : React.ReactElement => {
    return <>
        {displayIcon === 'CartIcon' && (
            <Button
                variant="contained"
                onClick={handleFeeQuestionsModal}
                sx={{
                    backgroundColor: '#631d79',
                    textTransform: 'none',
                    "&:hover": {
                        backgroundColor: "#F0F0F0",
                        color: '#631d79',
                    },
                    whiteSpace: 'nowrap',
                    px: 1,
                    width: '100%'
                }}
            >
                Add to cart
            </Button>
        )}
        {displayIcon === 'InCart' && <Tooltip
            title='This fee is in the cart.'
        >
            <CartWithCheckbox/>
        </Tooltip>}
        {displayIcon === 'PaidIcon' && <Tooltip
            title='This Fee has been purchased.'
        >
            <PaidIcon/>
        </Tooltip>}
        {displayIcon === 'PartialIcon' && <Tooltip
            title='This Fee has been partially paid..'
        >
            <PartialPaid handleAddToCart={handleAddToCart} fee={assessedFee.fee}/>
        </Tooltip>}
        {displayIcon === 'BlockIcon' && <Tooltip
            title='This Fee has been purchased the maximum allowable times.'
        >
            <BlockIcon/>
        </Tooltip>}
    </>;
}

export default AssessedFeeCartIcon;
