import {IconDisplayOptions} from "@/types/IconDisplayOptions.ts";
import {JWTUser} from "@/components/Providers/JWTProvider.tsx";
import {AssessedFee} from "@/types/AssessedFee.ts";
import {getFeeDisplayIconType} from "@/Helpers/getFeeDisplayIconType.ts";
import {STATUS_PAID, STATUS_PARTIAL} from "@/components/Providers/ProgramsProvider.tsx";
import {PurchaseHistory} from "@/components/Providers/FeesProvider.tsx";

export const getAssessedFeeDisplayIconType = (
    isInCart: boolean,
    assessedFee: AssessedFee,
    user: JWTUser | null,
    purchaseHistory: PurchaseHistory | undefined,
) => {
    let displayIcon: IconDisplayOptions = 'CartIcon';

    if (assessedFee.fee) {
        displayIcon = getFeeDisplayIconType(isInCart, assessedFee.fee, user, purchaseHistory)
    }

    if (assessedFee.status === STATUS_PARTIAL) {
        displayIcon = 'PartialIcon';
    }

    if (assessedFee.status === STATUS_PAID) {
        displayIcon = 'PaidIcon';
    }

    if (isInCart) {
        displayIcon = 'InCart';
    }

    return displayIcon;
}
