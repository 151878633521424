import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CheckIcon from '@mui/icons-material/Check';
import {Box, type SxProps, Tooltip} from "@mui/material";

type Props = {
    sx?: SxProps
}
const CartWithCheckbox = ({sx} : Props) => {
    return <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            justifyContent: 'center',
            p: 1,
            ...sx
        }}
    >
        <Tooltip title='Item is in the cart.'>
            <Box
                sx={{
                    width: '24px',
                    height: '24px',
                    position: 'relative',
                }}
            >
                <AddShoppingCartIcon
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                />
                <CheckIcon
                    sx={{
                        position: 'absolute',
                        top: '18%',
                        left: '52%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        width: '46%',
                        height: '46%',
                        stroke: 'green',
                        strokeWidth: '2px',
                    }}
                />
            </Box>
        </Tooltip>
    </Box>
};

export default CartWithCheckbox;
