import React, {ReactNode, useContext, useEffect} from 'react';
import {jwtInstructorContext} from "./JWTProvider";
import {useHistory, useLocation} from "react-router-dom";
import {FeeRequest} from "@/types/FeeRequest.ts";
import {School} from "@/types/School.ts";
import {SchoolYear} from "@/types/SchoolYear.ts";
import {FeeCategory} from "@/types/FeeCategory.ts";
import {Employee} from "@/types/Employee.ts";
import {ApprovalRoute} from "@/types/ApprovalRoute.ts";
import {doGetSchools} from "@/components/Providers/instructor/doGetSchools.ts";
import {doGetSchoolYears} from "@/components/Providers/instructor/doGetSchoolYears.ts";
import {doGetApprovalRoutes} from "@/components/Providers/instructor/doGetApprovalRoutes.ts";
import {doGetEmployees} from "@/components/Providers/instructor/doGetEmployees.ts";
import {doGetFeeCategories} from "@/components/Providers/instructor/doGetFeeCategories.ts";
import {doGetFeeProposals} from "@/components/Providers/instructor/doGetFeeProposals.ts";

type FeeProposalsState = {
    loading: boolean;
    showAlert: boolean;
    feeProposals: Array<FeeRequest>;
    schools: Array<School>;
    schoolYears: Array<SchoolYear>;
    feeCategories: Array<FeeCategory>;
    employees: Array<Employee>;
    approvalRoutes: Array<ApprovalRoute>;
    message: string;
    alertVariant: string;
    refresh: (element ?: string) => Promise<void>;
    clearAlert: () => void;
}

type Props = {
    children?: ReactNode;
};

export const InstructorFeeProposalsProviderContext = React.createContext<
    FeeProposalsState
>(
    {
        loading: true,
        showAlert: false,
        feeProposals: [],
        schools: [],
        schoolYears: [],
        feeCategories: [],
        employees: [],
        approvalRoutes: [],
        message: '',
        alertVariant: 'success',
        refresh: async () => {},
        clearAlert: () => {},
    }
);

const feeProposalsReducer = (state: FeeProposalsState, action: any): FeeProposalsState => {
    switch (action.type) {
        case 'LOADING': {
            return {...state, loading: true, showAlert: false, alertVariant: ''}
        }
        case 'SET_FEE_PROPOSALS': {
            return {
                ...state,
                loading: false,
                showAlert: action.showAlert ?? state.showAlert,
                feeProposals: action.feeProposals ?? state.feeProposals,
                schools: action.schools ?? state.schools,
                schoolYears: action.schoolYears ?? state.schoolYears,
                feeCategories: action.feeCategories ?? state.feeCategories,
                employees: action.employees ?? state.employees,
                approvalRoutes: action.approvalRoutes ?? state.approvalRoutes,
                alertVariant: action.alertVariant ?? (state.alertVariant ?? 'success'),
                message: action.message ?? state.message,
                refresh: action.refresh ?? state.refresh,
                clearAlert: action.clearAlert ?? state.clearAlert,
            }
        }

        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
};

const InstructorFeeProposalsProvider: React.FC<Props> = ({children}: Props) => {
    const {user} = useContext(jwtInstructorContext);
    const location = useLocation();
    const history = useHistory();

    const clearAlert = () => {
        setFeeProposalsState({
            type: 'SET_FEE_PROPOSALS',
            showAlert: false,
            alertVariant: 'success',
            message: '',
        });
    };

    const refresh = async (keyName ?: string) => {
        if (!user?.apiFetch) {
            return;
        }

        try {
            const [schools, schoolYears, approvalRoutes, employees, feeCategories, feeProposals] = await Promise.all([
                (!keyName || keyName === 'schools') ? doGetSchools(user.apiFetch) : [...feeProposalsState.schools],
                (!keyName || keyName === 'schoolYears') ? doGetSchoolYears(user.apiFetch) : [...feeProposalsState.schoolYears],
                (!keyName || keyName === 'approvalRoutes') ? doGetApprovalRoutes(user.apiFetch) : [...feeProposalsState.approvalRoutes],
                (!keyName || keyName === 'employees') ? doGetEmployees(user.apiFetch) : [...feeProposalsState.employees],
                (!keyName || keyName === 'feeCategories') ? doGetFeeCategories(user.apiFetch) : [...feeProposalsState.feeCategories],
                (!keyName || keyName === 'feeProposals') ? doGetFeeProposals(user.apiFetch) : [...feeProposalsState.feeProposals]
            ]);

            setFeeProposalsState({
                type: 'SET_FEE_PROPOSALS',
                showAlert: false,
                feeProposals: feeProposals,
                schools: schools,
                schoolYears: schoolYears,
                feeCategories: feeCategories,
                employees: employees,
                approvalRoutes: approvalRoutes,
            });
        } catch (e: unknown) {
            console.info('e', e);

            setFeeProposalsState({
                type: 'SET_FEE_PROPOSALS',
                showAlert: true,
                alertVariant: 'danger',
                message: 'Error fetching data.',
            });
        }
    };

    const [feeProposalsState, setFeeProposalsState] = React.useReducer(
        feeProposalsReducer,
        {
            loading: true,
            showAlert: false,
            feeProposals: [],
            schools: [],
            schoolYears: [],
            feeCategories: [],
            employees: [],
            approvalRoutes: [],
            message: '',
            alertVariant: 'success',
            refresh: refresh,
            clearAlert: clearAlert,
        });

    useEffect(() => {
        void refresh();

        const interval = setInterval(() => {
            if (document.hidden || !user?.apiFetch) {
                return;
            }

            void refresh();
        }, 5 * 60 * 1000);//update every 5 minutes

        return () => clearInterval(interval);
    }, [user]);

    useEffect(() => {
        if (user && location.pathname === '/instructor/sign-in') {
            history.push('/instructor/');
        }
    }, [user, location])

    return (
        <InstructorFeeProposalsProviderContext.Provider
            value={
                feeProposalsState
            }>
            {children}
        </InstructorFeeProposalsProviderContext.Provider>
    );
};

export const useFeeProposalsProvider = () : FeeProposalsState => {
    return useContext(InstructorFeeProposalsProviderContext);
};

export default InstructorFeeProposalsProvider;

