import {useCallback, useContext, useState} from 'react';
import {Container} from "react-bootstrap";
import {FormikValues} from "formik";
import {JWTInstructor, jwtInstructorContext, useAuthenticateInstructor,} from "../../components/Providers/JWTProvider";
import SystemAlert from "../../components/SystemAlert";
import InstructorSignInForm from "../../components/Forms/InstructorSignInForm";
import {useHistory} from "react-router-dom";
import SignInHeader from "@/components/Navigation/SignInHeader.tsx";
import {Stack} from '@mui/material';

export const signInError = 'Invalid Username or Password';
const allowedRoutes = ['/instructor', '/instructor/fee-proposals'];



const InstructorSignIn = () => {
    const {setUser} = useContext(jwtInstructorContext);
    const authenticateInstructor = useAuthenticateInstructor();
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const history = useHistory();

    const handleSignInSubmit = useCallback(async (values : FormikValues) => {
        const user : JWTInstructor | null = await authenticateInstructor(values.username, values.password);
        if (user) {
            setUser(user);
            if (!allowedRoutes.includes(window.location.pathname)) {
                if (user.role.includes('instructor')) {
                    history.push('/instructor/');
                } else if (user.role.includes('fee_admin')) {
                    history.push('/instructor/fee-proposals')
                } else {
                    history.push('/instructor/');
                }
            }//else wait for the next render due to setUser above
        } else {
            setShowAlert(true);
        }
    }, [authenticateInstructor, setShowAlert]);

    const handleClearAlert = useCallback(async () => {
        setShowAlert(false);
    }, [setShowAlert]);

    return (
        <>
            <SignInHeader showNav={true} />
            <Container>
                <Stack sx={{justifyContent: 'center', alignItems: 'center', p: 5}}>
                    <Stack sx={{maxWidth: '500px'}}>
                            <SystemAlert
                                show={showAlert}
                                alertText={signInError}
                                clearSystemAlert={handleClearAlert}
                                variant={'danger'}
                                isFixed={false}
                            />
                            <InstructorSignInForm handleSignInSubmit={handleSignInSubmit}/>
                    </Stack>
                </Stack>
            </Container>
        </>
    );
};

export default InstructorSignIn;
