import {Box, Button, Tooltip} from "@mui/material";
import PaidIcon from "@mui/icons-material/Paid";
import {Fee} from "@/types/Fee.ts";

type Props = {
    handleAddToCart: (fee: Fee, includeQuestions: boolean) => Promise<void>;
    fee: Fee;
};

const PartialPaid = ({handleAddToCart, fee} : Props) => {
    return <Button
        onClick={async () => {
            await handleAddToCart(fee, false);
        }}
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: 1,
        }}
    >
        <Tooltip title='Add partially paid fee to cart.'>
            <Box
                sx={{
                    width: '24px',
                    height: '24px',
                    position: 'relative',
                }}
            >
                <PaidIcon
                    sx={{
                        width: '100%',
                        height: '100%',
                        clipPath: 'inset(0 50% 0 0)',
                        position: 'absolute',
                        color: '#aaa',
                    }}
                />
                <PaidIcon
                    sx={{
                        width: '100%',
                        height: '100%',
                        color: '#000',
                        backgroundColor: '#fff',
                        clipPath: 'inset(0 50% 0 100%)',
                    }}
                />
            </Box>
        </Tooltip>
    </Button>
};

export default PartialPaid;
