import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';

type Props = {
    feeCategory: string;
    setFeeCategory: (feeCategory: string) => void;
    feeCategories: string[];
};

 const CategorySelect = ({feeCategory, setFeeCategory, feeCategories} : Props) : React.ReactElement => {
    const handleChange = (event: SelectChangeEvent) => {
        setFeeCategory(event.target.value);
    };

     return (
        <FormControl
            sx={{
                mr: {xs: 0, lg: 1},
                mb: {xs: 1, lg: 0},
                minWidth: '49.5%',
            }}
            size="small"
        >
            <InputLabel>Filter by Category</InputLabel>
            <Select
                labelId="sort-by-category-label"
                id="sort-by-category"
                value={feeCategory}
                label="Sort by Category"
                onChange={handleChange}
            >
                <MenuItem value=''>All</MenuItem>

                {feeCategories.map(feeCategory => {
                    return <MenuItem
                        key={`category-select-${feeCategory}`}
                        value={feeCategory}
                    >
                        {feeCategory}
                    </MenuItem>
                })}
            </Select>
        </FormControl>
    );
}

export default CategorySelect;
