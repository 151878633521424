import {Button, Grid, Stack, SxProps, Typography} from "@mui/material";
import {formatter} from "@/Helpers/formatter.ts";
import {useHistory} from "react-router-dom";
import {RefObject} from "react";
import {FormikProps} from "formik";
import {CartPaymentFormFormValues} from "@/pages/CartPage.tsx";

type Props = {
    totalCost : number;
    cartIsEmpty : boolean;
    errorMessage : string;
    formikRef ?: RefObject<FormikProps<CartPaymentFormFormValues>>;
    sx ?: SxProps;
    cartPage ?: boolean;
}

const TotalRow = ({totalCost, cartIsEmpty, errorMessage, formikRef, cartPage = false, sx = {}}: Props) => {
    const history = useHistory();

    return <Stack
        sx={{
            flexDirection: {xs: 'column', lg: 'row'},
            justifyContent: 'center',
            px: 2,
            pb: 2,
            ...sx,
        }}
    >
        {!cartIsEmpty && <Button
            variant="contained"
            onClick={async () => {
                if (cartPage) {
                    if(formikRef ) {
                        await formikRef.current?.submitForm()
                    }
                    return;
                }

                history.push('/cart');
            }}
            sx={{
                mt: 3,
                mb: 1,
                fontWeight: 800,
            }}
        >
            {cartPage ? `Pay ${formatter.format(totalCost)} Now` : `Pay ${formatter.format(totalCost)}`}
        </Button>}
        {cartIsEmpty && <Typography variant={'h6'} sx={{mt: 3, mb: 1, fontWeight: 800}}>
            Your cart is empty.
        </Typography>}
        {errorMessage && <Grid item xs={12} sx={{color: 'red'}}>
            {errorMessage}
        </Grid>}
    </Stack>;
}

export default TotalRow;
