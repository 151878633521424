import {useContext, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {InstructorProgramsProviderContext} from "../../components/Providers/InstructorProgramsProvider";
import ProgramSelectField from "../../components/Forms/Fields/ProgramSelectField";
import Form from "react-bootstrap/Form";
import InstructorHeader from "../../components/Navigation/InstructorHeader";
import {useHistory, useParams} from "react-router-dom";
import InstructorCourseList from "@/components/Program/InstructorCourseList.tsx";

const InstructorProgram = () => {
    const {programId} = useParams<{programId: string}>();
    const history = useHistory();
    const [activeFilter, setActiveFilter] = useState<boolean>(true);
    const context = useContext(InstructorProgramsProviderContext);

    const availablePrograms = context.programs.map(p => p.programName)

    const selectedProgram = context.programs.find(p => p.id === programId);
    if (!selectedProgram) {
        history.push(`/instructor/`);
        return <></>;
    }

    const courses = selectedProgram.active === activeFilter ? selectedProgram.courseSelections : []

    return <>
        <InstructorHeader
            showNav={true}
        />
        <Container>
            <Row className="mt-5">
                <Col className="inline-program-select">
                    <span className="background-font">Program Selected: </span>
                    <ProgramSelectField
                        availablePrograms={availablePrograms}
                        setActiveProgram={(name) => {
                            const program = context.programs.find(p => p.programName === name);
                            if (!program) {
                                history.push(`/instructor/`);
                            } else {
                                history.push(`/instructor/program/${program.id}`);
                            }
                        }}
                        defaultProgram={selectedProgram?.programName}
                        className="form-inline"
                    />
                </Col>
            </Row>
            <br/>

            <Row className="d-flex flex-row justify-content-center bg-white p-5">
                <Row className="col-sm-12">
                    <Form.Control
                        type={'select'}
                        as="select"
                        name={'availablePrograms'}
                        value={activeFilter ? 'true' : ''}
                        onChange={async (e) => {
                            setActiveFilter(e.currentTarget.value === 'true');
                        }}
                        disabled={false}
                        className={`w-50`}
                    >
                        <option value="true">Active Activities</option>
                        <option value="">Inactive Activities</option>
                    </Form.Control>
                </Row>
                <Col sm={12} className="py-3 mx-0 px-0 main-content">
                    <InstructorCourseList data={courses} programId={selectedProgram.id}/>
                </Col>
            </Row>
        </Container>
    </>
}

export default InstructorProgram;
