import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

export type AssessedOptionalToggle = 'assessed' | 'optional';

type Props = {
    feeToggleValue: AssessedOptionalToggle;
    setFeeToggleValue: (feeToggleValue: AssessedOptionalToggle) => void;
};

const FeeToggle = ({feeToggleValue, setFeeToggleValue} : Props) : React.ReactElement => {
    const handleChange = (_event: React.MouseEvent<HTMLElement, MouseEvent>, value: AssessedOptionalToggle) => {
        if (value) {
            setFeeToggleValue(value);
        }
    };

    return (
        <FormControl sx={{ my: 1, minWidth: 220, width: '100%'}} size="small">
            <ToggleButtonGroup
                color="primary"
                value={feeToggleValue}
                exclusive
                onChange={handleChange}
                aria-label="OptionalFeeListItem Toggle"
                fullWidth
            >
                <ToggleButton value="assessed">Assessed Fees</ToggleButton>
                <ToggleButton value="optional">Optional Fees</ToggleButton>
            </ToggleButtonGroup>
        </FormControl>
    );
}

export default FeeToggle;
