import * as React from 'react';
import {useMemo, useState} from 'react';
import {Box, Card, Grid, Stack, Tooltip, Typography} from "@mui/material";
import {AssessedFee} from "@/types/AssessedFee.ts";
import ViewMoreModalLink from "@/components/Fees/ViewMoreModalLink.tsx";
import {formatter} from "@/Helpers/formatter.ts";
import LockIcon from "@mui/icons-material/Lock";
import {jwtContext} from "@/components/Providers/JWTProvider.tsx";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";
import {useFeeProvider} from "@/components/Providers/FeesProvider.tsx";
import FeeQuestionModal from "@/components/Modal/FeeQuestionModal.tsx";
import {Fee} from "@/types/Fee.ts";
import {getAssessedFeeDisplayIconType} from "@/Helpers/getAssessedFeeDisplayIconType.ts";
import AssessedFeeCartIcon from "@/components/Fees/AssessedFeeCartIcon.tsx";
import {buildFeePrice} from "@/Helpers/buildFeePrice.ts";

type Props = {
    assessedFee: AssessedFee;
    handleAddToCart: (fee: Fee, includeCourseQuestions?: boolean) => Promise<void>;
};

const AssessedFeeListItem = ({assessedFee, handleAddToCart} : Props) : React.ReactElement => {
    const user = React.useContext(jwtContext);
    const {cart} = useCartContext();
    const {purchaseHistory} = useFeeProvider();
    const [showCourseQuestionModal, setShowCourseQuestionModal] = useState<boolean>(false);

    const isInCart = useMemo(() => {
        return cart?.cartItems
            .some(item => item.itemType === 'fee' && item.assessedFeeId === assessedFee.id) ?? false;
    }, [cart, assessedFee])

    const displayIcon = useMemo(() => {
        return getAssessedFeeDisplayIconType(isInCart, assessedFee, user, purchaseHistory)
    }, [isInCart, assessedFee, user, purchaseHistory]);

    const amountPaid = useMemo(() => {
            return purchaseHistory?.partialStudentFeeIdsAndAmountRemaining[assessedFee.id] ?? 0;
        }, [purchaseHistory?.partialStudentFeeIdsAndAmountRemaining, assessedFee]);

    const handleFeeQuestionsModal = async () => {
        if (assessedFee.fee.courseQuestions.length) {
            setShowCourseQuestionModal(true);
            return;
        }

        await handleAddToCart(assessedFee.fee);
    }

    return <Card
        sx={{
            '&:nth-of-type(odd)': {
                backgroundColor: "#eee",
            },
            '&:nth-of-type(even)': {
                backgroundColor: "white",
            },
            p: 1,
            mb: 1,
            border: '1px solid #ccc',
        }}
    >
        <Grid container sx={{mb: 2}}>
            <Grid
                item
                xs={12}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    fontWeight: 800,
                    fontSize: '1.5em',
                }}>
                <Box sx={{fontWeight: 800}}>{assessedFee.fee.name}</Box>
                <Stack sx={{flexDirection: 'row', justifyContent: 'center'}}>
                    <Box sx={{display: 'inline'}}>{formatter.format(buildFeePrice(assessedFee.fee, user, purchaseHistory)-amountPaid)}</Box>
                </Stack>
            </Grid>
            <Grid item xs={12} md={9}>
                <Box sx={{display: 'inline', mr: 1, pr: 1}}>
                    {assessedFee.fee.description.substring(0, 120).split('¶').map((line) => <Box style={{display: 'inherit'}}>{line}</Box>)}
                </Box>
                <Box sx={{display: 'inline'}}>
                    <ViewMoreModalLink fee={assessedFee.fee}/>
                </Box>
            </Grid>
            <Grid item xs={12} md={3} sx={{textAlign: 'right', display: {xs: 'none', lg: 'block'} }}>
                <AssessedFeeCartIcon
                    displayIcon={displayIcon}
                    handleFeeQuestionsModal={handleFeeQuestionsModal}
                    assessedFee={assessedFee}
                    handleAddToCart={handleAddToCart}
                />
            </Grid>
            <Grid
                item
                xs={12} md={9}
                sx={{
                    textAlign: {xs: 'center', lg:'right'},
                    display: 'flex',
                    flexDirection: {xs: 'column'},
                }}
            >
                {Boolean(!assessedFee.fee.allowPartialPayment) && <Stack
                    sx={{
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Tooltip title="Requires Payment in Full">
                        <LockIcon/>
                    </Tooltip>
                    <Typography
                        variant='body2'
                        sx={{
                            textAlign:  'left',
                        }}
                    >
                        Requires Payment in Full
                    </Typography>
                </Stack>}
                <Box sx={{display: {xs: 'block', lg: 'none'}, pt: 1}}>
                    <AssessedFeeCartIcon
                        displayIcon={displayIcon}
                        assessedFee={assessedFee}
                        handleFeeQuestionsModal={handleFeeQuestionsModal}
                        handleAddToCart={handleAddToCart}
                    />
                </Box>
            </Grid>
        </Grid>
        {assessedFee.fee.courseQuestions && <FeeQuestionModal
            show={showCourseQuestionModal}
            handleClose={() => setShowCourseQuestionModal(false)}
            fee={assessedFee.fee}
            performActionBasedOnCourseStatus={handleAddToCart ?? (async () => {})}
        />}
    </Card>;
}

export default AssessedFeeListItem;
