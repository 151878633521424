import NavButton from "@/components/Navigation/NavButton.tsx";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const CartNavButton = () => {
    const {cart} = useCartContext();

    return (<>
            <NavButton
                text='Cart'
                link='/cart'
                icon={<ShoppingCartIcon/>}
                badges={cart?.cartItems.length ?? 0}
            />
        </>
    );
};

export default CartNavButton;
