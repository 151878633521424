import {useCallback, useContext} from 'react';
import {jwtInstructorContext} from "@/components/Providers/JWTProvider.tsx";
import {FeeProposalRequestSchemaType} from "@/components/Forms/FeeProposalRequestForm.tsx";

const useSaveFeeProposal = (): (data: FeeProposalRequestSchemaType) => Promise<Response | void> => {
    const {user} = useContext(jwtInstructorContext);

    return useCallback(async (data: FeeProposalRequestSchemaType): Promise<Response | void> => {
        if (!user) {
            throw new Error('User not authenticated');
        }

        const url = new URL('/v1/fee-requests/save', import.meta.env.VITE_APP_API_ENDPOINT);

        try {
            return await user.apiFetch(url.toString(), {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
        } catch (error) {
            console.error('Error saving fee proposal:', error);
            throw error;
        }
    }, []);
};

export default useSaveFeeProposal;
