import {type ReactElement, useState} from "react";
import {Box, Divider, Stack, Typography} from "@mui/material";
import MainDisplayContainer from "@/components/Layout/MainDisplayContainer.tsx";
import FeeProposals from "@/components/Fees/FeeProposals.tsx";
import FeeProposalRequestForm from "@/components/Forms/FeeProposalRequestForm.tsx";
import {FormProvider, useForm} from "react-hook-form";
import InstructorHeader from "@/components/Navigation/InstructorHeader.tsx";
import {useFeeProposalsProvider} from "@/components/Providers/InstructorFeeProposalsProvider.tsx";
import SystemAlert from "@/components/SystemAlert.tsx";
import {FeeRequest} from "@/types/FeeRequest.ts";
import StatusHistoryModal from "@/components/Fees/StatusHistoryModal.tsx";

const FeeProposalsPage = () : ReactElement => {
    const methods = useForm()
    const {
        feeProposals,
        showAlert,
        alertVariant,
        message,
        clearAlert,
    } = useFeeProposalsProvider();

    const [showStatusHistoryModal, setShowStatusHistoryModal] = useState<boolean>(false);
    const [showFeeProposalForm, setShowFeeProposalForm] = useState<boolean>(false);
    const [selectedFeeRequest, setSelectedFeeRequest] = useState<FeeRequest | null>(null);

    return <Stack sx={{backgroundColor: '#631d79', flexDirection: 'column', height: '100vh'}}>
        <InstructorHeader showNav={true}/>
        <MainDisplayContainer>
            <FormProvider {...methods}>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: {xs: 'column-reverse', md: 'row'},
                        overflowY: 'scroll',
                        px: '49.5px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            width: '100%',
                            px: 2,
                            pt: 2,
                            overflowY: 'auto',
                            backgroundColor: '#fff',
                        }}
                    >
                        <Stack
                            sx={{
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'space-between',
                                mb: 1,
                            }}
                        >
                            <Typography
                                variant={'h5'}
                                sx={{
                                    pt: 1,
                                    pb: 1,
                                    color: '#000',
                                }}
                            >
                                My Fee Requests
                            </Typography>

                            <FeeProposalRequestForm show={showFeeProposalForm} setShow={setShowFeeProposalForm} feeRequest={selectedFeeRequest} setFeeRequest={setSelectedFeeRequest}/>
                        </Stack>
                        <Divider/>
                        {feeProposals && <FeeProposals
                            feeRequests={feeProposals}
                            setShowForm={setShowFeeProposalForm}
                            setSelectedFeeRequest={setSelectedFeeRequest}
                            setShowStatusHistoryModal={setShowStatusHistoryModal}
                        />}
                    </Box>
                </Box>
            </FormProvider>
            <SystemAlert
                show={showAlert}
                alertText={message}
                clearSystemAlert={clearAlert}
                variant={alertVariant}
                isFixed={false}
            />
            <StatusHistoryModal
                show={showStatusHistoryModal}
                handleClose={() => {
                    setShowStatusHistoryModal(false);
                    setSelectedFeeRequest(null);
                }}
                feeRequest={selectedFeeRequest}
            />
        </MainDisplayContainer>
    </Stack>
};

export default FeeProposalsPage;
