import {useContext} from 'react';
import {Container} from "react-bootstrap";
import {InstructorProgramsProviderContext} from "../../components/Providers/InstructorProgramsProvider";
import InstructorHeader from "../../components/Navigation/InstructorHeader";
import ProgramSelection from "../../components/Program/ProgramSelection";
import {useHistory} from "react-router-dom";

const InstructorProgramList = () => {
    const context = useContext(InstructorProgramsProviderContext);
    const history = useHistory();

    const availablePrograms = context.programs.map(p => p.programName)


    return <>
        <InstructorHeader showNav={true}/>
        <Container className="pt-5">
            <ProgramSelection
                availablePrograms={availablePrograms}
                loading={false}
                setActiveProgram={(name) => {
                    const program = context.programs.find(p => p.programName === name);
                    if (program) {
                        history.push(`/instructor/program/${program.id}`);
                    }
                }}
            />
        </Container>
    </>
}

export default InstructorProgramList;
