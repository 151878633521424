import NavHeader from "../components/Navigation/NavHeader";
import {type ReactElement, useState} from "react";
import {Box, Divider, Stack, Typography} from "@mui/material";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";
import SystemAlert from "@/components/SystemAlert.tsx";
import TotalRow from "@/components/Cart/TotalRow.tsx";
import MainDisplayContainer from "@/components/Layout/MainDisplayContainer.tsx";
import AssessedFeeList from "@/components/Fees/AssessedFeeList.tsx";
import OptionalFeeList from "@/components/Fees/OptionalFeeList.tsx";
import FeeToggle, {AssessedOptionalToggle} from "@/components/Fees/FeeToggle.tsx";
import CartSummaryList from "@/components/Cart/CartSummaryList.tsx";
import {useFeeProvider} from "@/components/Providers/FeesProvider.tsx";

const FeePage = () : ReactElement => {
    const {getCartAlertStatus, resetCartAlertStatus, getCartTotal, cartIsEmpty} = useCartContext();
    const {optionalFees, assessedFees} = useFeeProvider();
    const totalCost = getCartTotal();
    const cartStatus = getCartAlertStatus();
    const [errorMessage] = useState('');
    const [feeToggleValue, setFeeToggleValue] = useState<AssessedOptionalToggle>('assessed');

    return <Stack sx={{backgroundColor: '#631d79', flexDirection: 'column', height: '100vh'}}>
        <NavHeader showNav={true}/>
        <MainDisplayContainer>
            <Box
                sx={{
                    height: '100%',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: {xs: 'column-reverse', md: 'row'},
                    overflowY: {xs: 'auto', md: 'hidden'}
                }}
            >
                <Stack
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        width: {xs: '100%', md: '60%'},
                        px: 2,
                        pt: 2,
                        backgroundColor: '#fff',
                    }}
                >
                    <Box>
                        <Typography
                            variant={'h5'}
                            sx={{
                                pt: 1,
                                pb: 1,
                                color: '#000',
                            }}
                        >
                            Student Fees
                        </Typography>
                        <Divider/>
                    </Box>
                    <Stack
                        sx={{
                            flexDirection: 'column',
                            flexGrow: 1,
                            // height: '100%',
                            overflowY:  'hidden',
                        }}
                    >
                        <Box>
                            <FeeToggle feeToggleValue={feeToggleValue} setFeeToggleValue={setFeeToggleValue}/>
                        </Box>
                        <Stack
                            sx={{
                                flexDirection: 'column',
                                flexGrow: 1,
                                overflowY:  'auto',
                            }}
                        >
                            {feeToggleValue === 'assessed' && assessedFees && <AssessedFeeList assessedFees={assessedFees}/>}
                            {feeToggleValue === 'optional' && optionalFees && <OptionalFeeList optionalFees={optionalFees}/>}
                        </Stack>
                    </Stack>
                </Stack>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        width: {xs: '100%', md: '40%'},
                        backgroundColor: '#f8f8f8',
                        px: 1,
                        pt: 2,
                    }}
                >
                    <Typography
                        variant={'h5'}
                        sx={{
                            py: 1,
                            color: '#000',
                        }}
                    >
                        Cart Summary
                    </Typography>
                    <Divider/>

                    <CartSummaryList/>

                    <TotalRow
                        totalCost={totalCost}
                        cartIsEmpty={cartIsEmpty}
                        errorMessage={errorMessage}
                        sx={{
                            display: {xs: 'none', md: 'flex'},
                            backgroundColor: '#fff',
                        }}
                    />
                </Box>
            </Box>
            <SystemAlert
                show={cartStatus.showAlert}
                alertText={cartStatus.message}
                clearSystemAlert={resetCartAlertStatus}
                variant={cartStatus.alertVariant}
                isFixed={true}
            />
        </MainDisplayContainer>
    </Stack>
};

export default FeePage;
