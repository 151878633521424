import {Alert, Button, Container, Stack} from '@mui/material';
import {useStripe} from '@stripe/react-stripe-js';
import type * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import useStripeCompleteSetupIntent from '@/hooks/useStripeCompleteSetupIntent';
import {jwtContext} from "@/components/Providers/JWTProvider.tsx";
import {CreateSetupIntentResponse} from "@/types/CreateSetupIntentResponse.ts";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";
import {useFeeProvider} from "@/components/Providers/FeesProvider.tsx";

const PaymentComplete = () : React.ReactElement => {
    const stripe = useStripe();
    const history = useHistory();
    const searchParams = new URLSearchParams(window.location.search);
    const user = useContext(jwtContext)
    const {refreshCart} = useCartContext();
    const {refreshPurchaseHistory} = useFeeProvider();
    const completeSetupIntent = useStripeCompleteSetupIntent();
    const [paymentProcessed, setPaymentProcessed] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [secondsUntilRedirect, setSecondsUntilRedirect] = useState<number | null>(null);

    useEffect(() => {
        const doCompletePayment = async () => {
            const cartId = searchParams.get('cartId');
            const payNowAmount = searchParams.get('payNowAmount');

            if (
                user !== null
                && user !== undefined
                && cartId !== null
                && payNowAmount !== null
                && stripe
            ) {
                setPaymentProcessed(true);

                const setupIntentId = searchParams.get('setup_intent');
                const setupIntentClientSecret = searchParams.get('setup_intent_client_secret');

                if (setupIntentId && setupIntentClientSecret) {
                    const retrieveSetupIntentResponse = await stripe.retrieveSetupIntent(setupIntentClientSecret);

                    if (retrieveSetupIntentResponse.error) {
                        setErrorMessage(
                            retrieveSetupIntentResponse.error.message ?? 'An unknown stripe error has occurred.'
                        );
                        return;
                    }

                    const response = await completeSetupIntent(
                        user,
                        setupIntentId,
                        setupIntentClientSecret,
                        retrieveSetupIntentResponse.setupIntent.payment_method as string,
                        cartId,
                        null,
                        Number.parseInt(payNowAmount),
                        retrieveSetupIntentResponse.setupIntent.id as string,
                    );

                    if (response.ok) {
                        const json = await response.json() as CreateSetupIntentResponse;

                        if (json.errorCode !== 0) {
                            setErrorMessage(json.message);
                            return;
                        }

                        await refreshCart();
                        await refreshPurchaseHistory();
                        setSuccessMessage('Payment Complete');
                        setSecondsUntilRedirect(0);
                    } else {
                        setErrorMessage('An unknown error has occurred.');
                    }
                }
            }
        };

        if (!paymentProcessed) {
            void doCompletePayment();
        }
    }, [user, stripe]);

    useEffect(() => {
        if (secondsUntilRedirect !== null) {
            const intervalId = window.setInterval(() => {
                if (secondsUntilRedirect > 0) {
                    setSecondsUntilRedirect(secondsUntilRedirect - 1);
                    return;
                }

                history.push('/');
            }, 1000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [secondsUntilRedirect]);

    return <Stack direction='column' alignItems='center'>
        <Container>
            {errorMessage && <Stack direction='column' alignItems='center'>
                <Stack
                    direction='row'
                    justifyContent='center'
                    sx={{mb: 2}}
                >
                    There has been an error processing your request.
                </Stack>
                <Alert severity='error'>{errorMessage}</Alert>
                <Button
                    variant='outlined'
                    onClick={() => {
                        history.push('/');
                    }}
                    sx={{mt: 2}}
                >
                    Click here to return to Registration
                </Button>
            </Stack>}
            {successMessage && <Stack direction='column' alignItems='center'>
                <Stack
                    direction='row'
                    justifyContent='center'
                    sx={{mb: 2}}
                >
                    You will be redirected to Registration in {secondsUntilRedirect}...
                </Stack>
                <Alert severity='success'>{successMessage}</Alert>
                <Button
                    variant='outlined'
                    onClick={() => {
                        history.push('/');
                    }}
                    sx={{mt: 2}}
                >
                    Return to Registration Now
                </Button>
            </Stack>}
        </Container>
    </Stack>;
};

export default PaymentComplete;
