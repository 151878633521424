import * as React from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {useFormContext} from 'react-hook-form';
import {DatePicker} from "@mui/x-date-pickers";
import {SxProps} from "@mui/material";
import {ZonedDateTime} from "@js-joda/core";
import moment from "moment";

export type Props = {
    name : string;
    label : string;
    defaultValue ?: ZonedDateTime | null;
    sx ?: SxProps;
};

const MuiDatePicker = ({name, label, defaultValue, sx} : Props) : React.ReactElement => {
    const {setValue, trigger, formState} = useFormContext();

    return <DatePicker
        label={label}
        name={name}
        slotProps={{
            textField: {
                helperText: formState.errors[name] ? String(formState.errors[name]?.message) : '',
                error: !!formState.errors[name],
            },
        }}
        onChange={async (value) => {
            if (value) {
                setValue(name, value.toDate());
                await trigger(name);
                return
            }

            setValue(name, null);
            await trigger(name);
        }}
        value={defaultValue ? moment(defaultValue.toLocalDateTime().toString()) : null}
        sx={{
            '& .MuiInputBase-root': {
                pr: '24px',
            },
            ...sx,
        }}
    />
};

export default MuiDatePicker;
