import {Form, Formik, FormikValues} from "formik";
import moment from "moment";
import {useState} from 'react';
import {Col, Row} from "react-bootstrap";
import ReactMarkdown from 'react-markdown';
import * as yup from 'yup';
import RecoverEmailModal from "../Modal/RecoverEmailModal";
import {useNotice} from "../Providers/NoticeProvider";
import StandardField from "./Fields/StandardField";
import PrimaryButton from "@/components/PrimaryButton.tsx";
import {Login} from "@mui/icons-material";
import {Button, Link} from "@mui/material";
import {useHistory} from "react-router-dom";

type Props = {
    handleSignInSubmit: (values: FormikValues) => void;
};

const today = new Date();

const parseDateString = (orginalValue: string): null | Date => {
    const date = moment(orginalValue, 'MM/DD/YYYY', true);
    return date.isValid() ? date.toDate() : null;
}

const SignInForm = ({
    handleSignInSubmit
}: Props) => {
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [showForgotCommunityMemberIdModal, setShowForgotCommunityMemberIdModal] = useState<boolean>(false);
    const [isForgotStudent, setIsForgotStudentId] = useState<boolean>(false);
    const [notice] = useNotice();

    const initialValues = {
        studentId: '',
        dob: ''
    };

    const schema = yup.object({
        studentId: yup.string().label('Student ID').required(),
        dob: yup.string().label('Birth Date').test(
            'date',
            '${path} must be formatted MM/DD/YYYY',
            (value): boolean => {
                if (!value) {
                    return false;
                }
                const date = parseDateString(value);
                return date !== null && date < today;
            }).required()
    });

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={async (values) => {
                setLoading(true);
                handleSignInSubmit(values);
                setLoading(false);
            }}
            enableReinitialize={true}
            validateOnChange={false}
        >
            {() => {
                return <>
                    <Form className="form-bg pt-2">
                        <Row className="mx-0 p-2">
                            <Col xs={12}>
                                <ReactMarkdown className="markdown">{notice.logInMessage}</ReactMarkdown>
                            </Col>
                            <Col xs={12}>
                                <StandardField
                                    name="studentId"
                                    type="text"
                                    label="Student or Community Member ID Number:"
                                    disabled={false}
                                    handleSetFormIsModified={async (_: boolean) =>  {}}
                                />
                            </Col>
                            <Col xs={12}>
                                <StandardField
                                    name="dob"
                                    type="text"
                                    label="Birth Date (MM/DD/YYYY):"
                                    disabled={false}
                                    handleSetFormIsModified={async (_: boolean) =>  {}}
                                    placeHolder="MM/DD/YYYY"
                                />
                            </Col>
                        </Row>
                        <Row className="mx-1">
                            <Col xs={12}>
                                <hr />
                            </Col>
                        </Row>
                        <Row className="mx-0 p-2">
                            <Col xs={12}>
                                <PrimaryButton
                                    type="submit"
                                    sx={{width: '100%'}}
                                    disabled={loading}
                                >
                                    <Login sx={{mr: 1}}/> Login
                                </PrimaryButton>
                            </Col>
                            <Col xs={12}>
                                <Button variant="outlined" fullWidth onClick={() => {
                                    history.goBack();
                                }} sx={{ mt: 2 }}>
                                    Back
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mx-0 p-2">
                            <Col xs={12}>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                        setIsForgotStudentId(true);
                                        setShowForgotCommunityMemberIdModal(true);
                                    }}
                                    sx={{pb: 1}}
                                >
                                    Forgot Student ID Number
                                </Link>
                            </Col>
                            <Col xs={12}>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                        setIsForgotStudentId(false);
                                        setShowForgotCommunityMemberIdModal(true);
                                    }}
                                >
                                    Forgot Community Member ID Number
                                </Link>
                                <RecoverEmailModal
                                    show={showForgotCommunityMemberIdModal}
                                    handleYes={() => setShowForgotCommunityMemberIdModal(false)}
                                    handleClose={() => setShowForgotCommunityMemberIdModal(false)}
                                    isStudent={isForgotStudent}
                                />
                            </Col>
                        </Row>
                    </Form>
                </>
            }}
        </Formik>
    );
};

export default SignInForm;
