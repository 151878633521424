import * as React from 'react';
import Button from '@mui/material/Button';
import {Fee} from "@/types/Fee.ts";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link} from "@mui/material";

type Props = {
    fee: Fee
}

const ViewMoreModalLink = ({fee} : Props) => {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => setOpen(false);

    return (
        <>
            <Link href="#" onClick={() => setOpen(true)} underline="none">
                View More...
            </Link>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-name"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-name">
                    {fee.name}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {fee.description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ViewMoreModalLink;
