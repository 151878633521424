import {faBan, faPlus} from "@fortawesome/free-solid-svg-icons";
import React, {useCallback, useEffect, useState} from 'react';
import ConfirmCancelRegistrationModal from "../Modal/ConfirmCancelRegistrationModal";
import PaymentCollectedModal from '../Modal/PaymentCollectedModal';
import PaymentModal from '../Modal/PaymentModal';
import RegistrationCancelled from "../Modal/RegistrationCancelled";
import RegistrationConfirmed from "../Modal/RegistrationConfirmed";
import {useNotice} from "../Providers/NoticeProvider";
import {
    ProgramCourse,
    ProgramsProviderContext,
    STATUS_CLOSED_NO_WAITLIST,
    STATUS_REGISTERED,
    STATUS_REGISTERED_WITH_CREDITS,
    STATUS_REGISTERED_WITH_CREDITS_PENDING_PAYMENT,
    STATUS_WAITLIST,
    STATUS_WAITLISTED
} from "../Providers/ProgramsProvider";
import UpdatePaymentButton from "@/components/Program/RegisterButtons/UpdatePaymentButton.tsx";
import CourseIsFullWithWaitListButton from "@/components/Program/RegisterButtons/CourseIsFullWithWaitListButton.tsx";
import RemoveFromWaitListButton from "@/components/Program/RegisterButtons/RemoveFromWaitListButton.tsx";
import CourseIsFullButton from "@/components/Program/RegisterButtons/CourseIsFullButton.tsx";
import RegisteredPendingPaymentButton from "@/components/Program/RegisterButtons/RegisteredPendingPaymentButton.tsx";
import RegisteredAddPayment from "@/components/Program/RegisterButtons/RegisteredAddPayment.tsx";
import RegisteredWithCreditsAndCancel from "@/components/Program/RegisterButtons/RegisteredWithCreditsAndCancel.tsx";
import MainRegisterButton from "@/components/Program/RegisterButtons/MainRegisterButton.tsx";
import RegisteredInCart from "@/components/Program/RegisterButtons/RegisteredInCart.tsx";
import {useHistory} from "react-router-dom";
import SubmitPaymentButton from "@/components/Program/RegisterButtons/SubmitPaymentButton.tsx";
import {Typography} from "@mui/material";
import RegisteredWithCancelButton from "@/components/Program/RegisterButtons/RegisteredWithCancelButton.tsx";

type Props = {
    course: ProgramCourse;
    programName: string;
    localStorageTimeout: string | null;
};

const RegisterButton = ({course, programName, localStorageTimeout}: Props) => {
    const history = useHistory();
    const [notice] = useNotice();
    const [loading, setLoading] = useState<boolean>(false);
    const [showConfirmCancelRegistrationModal, setShowConfirmCancelRegistrationModal] = useState<boolean>(false);
    const [showRegistrationCancelledModal, setShowRegistrationCancelledModal] = useState<boolean>(false);
    const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
    const [courseHasCreditsApplied, setCourseHasCreditsApplied] = useState<boolean>(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [, performRegistrationAction, , , , ,setErrorMessage, refreshRegistrations] = React.useContext(ProgramsProviderContext);
    const [confirmationId, setConfirmationId] = useState('');

    const addPaymentCallback = async () => {
        setCourseHasCreditsApplied(true);
        setShowPaymentModal(true);
    }

    const removeFromWaitListCallback = async () => {
        setShowConfirmCancelRegistrationModal(true);
    }

    const registerCallback = async (course: ProgramCourse) => {
        if (!performRegistrationAction) {
            throw 'Registration Action not defined.'
        }

        try {
            setLoading(true);
            const registered = await Promise.race([
                performRegistrationAction(course, programName),
                new Promise((_resolve, reject) => {
                    setTimeout(reject, 30 * 1000)// 30 second timeout.  if we didn't resolve by now just clear the loading
                })
            ]);
            setLoading(false);

            if (registered && course.requiresPayment && course.status !== STATUS_WAITLISTED) {
                setShowPaymentModal(true);
            }
        } catch (e) {
            console.error('rejected registering', e);
            setErrorMessage('The system is currently experiencing higher than usual demand. Please try your request again.')//GLENR-184
            setLoading(false);
        }
    }

    const cancelRegistrationCallback = async () => {
        setShowConfirmCancelRegistrationModal(true);
    }

    const updatePaymentCallback = async () => {
        setShowConfirmCancelRegistrationModal(false);
        setShowPaymentModal(true);
    }

    const registeredViewCart = async () => {
        setShowConfirmCancelRegistrationModal(false);
        history.push('/cart');
    }

    let button: React.ReactElement | null = <MainRegisterButton
        icon={faPlus}
        loading={loading}
        callback={registerCallback}
        course={course}
    />;

    if (course.paymentDue && course.isInCart) {
        button = <RegisteredInCart
            icon={faPlus}
            loading={loading}
            callback={registeredViewCart}
            course={course}
        />
    } else if (course.paymentDue && course.registrationStatus === 'partial') {
        button = <SubmitPaymentButton
            icon={faPlus}
            loading={loading}
            callback={updatePaymentCallback}
            course={course}
        />
    } else if (course.paymentDue && course.status !== 'open') {
        button = <UpdatePaymentButton
            icon={faPlus}
            loading={loading}
            callback={updatePaymentCallback}
            course={course}
        />
    } else {
        switch (course.status) {
            case STATUS_CLOSED_NO_WAITLIST:
                button = <CourseIsFullButton
                    loading={loading}
                    course={course}
                />
                break;
            case STATUS_WAITLIST:
                button = <CourseIsFullWithWaitListButton
                    icon={faPlus}
                    loading={loading}
                    course={course}
                    callback={registerCallback}
                />
                break;
            case STATUS_WAITLISTED:
                button = <RemoveFromWaitListButton
                    icon={faBan}
                    loading={loading}
                    callback={removeFromWaitListCallback}
                    course={course}
                />
                break;
            case STATUS_REGISTERED:
                if (showPaymentModal) {
                    button = <RegisteredPendingPaymentButton
                        loading={loading}
                        callback={cancelRegistrationCallback}
                        icon={faBan}
                        course={course}
                    />
                } else if (!course.requiresPayment) {
                    button = <RegisteredWithCancelButton
                        loading={loading}
                        callback={cancelRegistrationCallback}
                        icon={faBan}
                        course={course}
                    />
                } else {
                    button = <Typography variant={'body1'}>You are Registered</Typography>
                }
                break;
            case STATUS_REGISTERED_WITH_CREDITS_PENDING_PAYMENT:
                button = <RegisteredAddPayment
                    icon={faPlus}
                    loading={loading}
                    callback={addPaymentCallback}
                    course={course}
                />
                break;
            case STATUS_REGISTERED_WITH_CREDITS:
                button = <RegisteredWithCreditsAndCancel
                    icon={faBan}
                    loading={loading}
                    callback={cancelRegistrationCallback}
                    course={course}
                />
                break;
            default:
                break;
        }
    }

    const handleCloseCancelRegistration = useCallback(async (): Promise<void> => {
        setShowConfirmCancelRegistrationModal(false);
    }, [setShowConfirmCancelRegistrationModal]);

    const handleCancelRegistration = async () => {
        setShowConfirmCancelRegistrationModal(false);

        if (performRegistrationAction) {
            setLoading(true);
            await performRegistrationAction(course, programName);
            setLoading(false);
        }
    };

    const handleClosePaymentModal = useCallback(async (showCancelModal?: boolean, showConfirmModal?: boolean): Promise<void> => {
        setShowPaymentModal(false);

        if (showCancelModal) {
            setShowRegistrationCancelledModal(true);
        }

        if (showConfirmModal) {
            setShowConfirmationModal(true);
        }
    }, [setShowPaymentModal]);

    useEffect(() => {
        if (localStorageTimeout) {
            const registrationCourseJson = JSON.parse(localStorageTimeout);
            const timeout = Date.parse(registrationCourseJson.timeout);

            if (Date.now() < timeout) {
                setShowPaymentModal(true);
            }
        }
    }, [localStorageTimeout]);

    return (
        <>
            {button}
            <ConfirmCancelRegistrationModal
                show={!course.requiresPayment && showConfirmCancelRegistrationModal}
                message={notice.cancelPaymentRegistration}
                handleClose={handleCloseCancelRegistration}
                handleCancelRegistration={handleCancelRegistration}
            />
            <PaymentCollectedModal
                course={course}
                show={course.requiresPayment && showConfirmCancelRegistrationModal}
                handlePaymentCollectedModal={handleCloseCancelRegistration}
            />
            <PaymentModal
                course={course}
                programName={programName}
                show={showPaymentModal}
                handleClose={handleClosePaymentModal}
                setConfirmationId={setConfirmationId}
                switchPayment={course.paymentDue && !localStorageTimeout}
                courseHasCreditsApplied={courseHasCreditsApplied}
            />
            <RegistrationCancelled
                show={showRegistrationCancelledModal}
                handleClose={() => setShowRegistrationCancelledModal(false)}
            />
            <RegistrationConfirmed
                show={showConfirmationModal}
                handleClose={() => {
                        setShowConfirmationModal(false);
                        void refreshRegistrations();
                    }
                }
                confirmationNumber={confirmationId}
                isWaitlist={course.status === 'waitlisted'}
                confirmationMessage={notice.registrationPaymentConfirmed}
                waitlistMessage={notice.waitlistPaymentConfirmed}
                isPreRegistered={course.status === 'registered-with-credits-pending-payment' || course.status === 'registered-with-credits'}
            />
        </>
    );
};

export default RegisterButton;
