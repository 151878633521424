import NavButton from "@/components/Navigation/NavButton.tsx";
import RequestPageIcon from '@mui/icons-material/RequestPage';

const FeesNavButton = () => {
    return (<>
            <NavButton
                text='Fees'
                link='/fees'
                icon={<RequestPageIcon/>}
            />
        </>
    );
};

export default FeesNavButton;
