import {Form, Formik, FormikValues} from "formik";
import {useState} from 'react';
import {Col, Row} from "react-bootstrap";
import ReactMarkdown from 'react-markdown';
import * as yup from 'yup';
import {useNotice} from "../Providers/NoticeProvider";
import StandardField from "./Fields/StandardField";
import PrimaryButton from "@/components/PrimaryButton.tsx";
import {Login} from "@mui/icons-material";
import {Button} from "@mui/material";
import {useHistory} from "react-router-dom";

type Props = {
    handleSignInSubmit: (values: FormikValues) => void;
};



const InstructorSignInForm = ({
    handleSignInSubmit
}: Props) => {
    const [loading, setLoading] = useState(false);
    const history = useHistory()
    const [notice] = useNotice();

    const initialValues = {
        username: '',
        password: ''
    };

    const schema = yup.object({
        username: yup.string().label('User name').required(),
        password: yup.string().label('Password').required()
    });

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={async (values) => {
                setLoading(true);
                await handleSignInSubmit(values);
                setLoading(false);
            }}
            enableReinitialize={true}
            validateOnChange={false}
        >
            {() => {
                return <>
                    <Form className="form-bg pt-2">
                        <Row className="mx-0 p-2">
                            <Col xs={12}>
                                <ReactMarkdown className="markdown">{notice.instructorLogInMessage}</ReactMarkdown>
                            </Col>
                            <Col xs={12}>
                                <StandardField
                                    handleSetFormIsModified={async (_: boolean) =>  {}}
                                    name="username"
                                    type="text"
                                    label="Username:"
                                    disabled={false}
                                />
                            </Col>
                            <Col xs={12}>
                                <StandardField
                                    handleSetFormIsModified={async (_: boolean) =>  {}}
                                    name="password"
                                    type="password"
                                    label="Password:"
                                    disabled={false}
                                />
                            </Col>
                        </Row>
                        <Row className="mx-1">
                            <Col xs={12}>
                                <hr />
                            </Col>
                        </Row>
                        <Row className="mx-0 p-2">
                            <Col xs={12}>
                                <PrimaryButton
                                    type="submit"
                                    sx={{width: '100%'}}
                                    disabled={loading}
                                >
                                    <Login sx={{mr: 1}}/> Login
                                </PrimaryButton>
                            </Col>
                            <Col xs={12}>
                                <Button variant="outlined" fullWidth onClick={() => {
                                    history.goBack();
                                }} sx={{ mt: 2 }}>
                                    Back
                                </Button>
                            </Col>
                        </Row>

                    </Form>
                </>
            }}
        </Formik>
    );
};

export default InstructorSignInForm;
