import React from 'react';
import {Route, Switch} from 'react-router';
import NotFound from './pages/NotFound';
import Programs from "./pages/Programs";
import SignOut from "./pages/SignOut";
import SignIn from "./pages/SignIn";
import ProgramsProvider from "./components/Providers/ProgramsProvider";
import MyRegistrations from "./pages/MyRegistrations";
import Instructions from "./pages/Instructions";
import EditProfile from "./pages/EditProfile";
import CartPage from "@/pages/CartPage.tsx";
import FeePage from "@/pages/FeePage.tsx";
import {CartContextProvider} from "@/components/Providers/CartProvider.tsx";
import PaymentComplete from "@/pages/PaymentComplete.tsx";
import FeeProvider from "@/components/Providers/FeesProvider.tsx";

const Routes : React.FC = () => (
    <Switch>
        <ProgramsProvider>
            <FeeProvider>
                <CartContextProvider>
                    <Route path="/programs" exact component={Programs}/>
                    <Route path="/edit-profile" exact component={EditProfile}/>
                    <Route path="/my-registrations" exact component={MyRegistrations}/>
                    <Route path="/cart" exact component={CartPage}/>
                    <Route path="/fees" exact component={FeePage}/>
                    <Route path="/payment-complete" exact component={PaymentComplete}/>
                    <Route path="/instructions" exact component={Instructions}/>
                    <Route path="/signout" exact component={SignOut}/>
                </CartContextProvider>
            </FeeProvider>
        </ProgramsProvider>
        <Route path="/signin" exact component={SignIn}/>
        <Route path="*" component={NotFound}/>
    </Switch>
);

export default Routes;
