import {faChalkboardTeacher, faUserPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Nav, Navbar} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import NavBase from './NavBase';

type Props = {
    showNav ?: boolean;
}

const SignInHeader = ({showNav} : Props) => {
    let history = useHistory();

    return (
        <NavBase>
            <div className="text-right d-flex flex-row">
                <Navbar.Toggle aria-controls="chris-nav" />
                {showNav && (
                <Navbar.Collapse id="glenbrook-nav" className="">
                    <Nav className="mr-auto">
                        <Nav.Link
                            className="d-flex flex-column align-items-center menu-item px-2 pt-4"
                            onClick={() => {
                                history.push(`/register-community-member`);
                            }}
                        >
                            <span className="align-items-center flex-column d-flex">
                                <FontAwesomeIcon icon={faUserPlus} size="lg" className="cursor-pointer" />
                                <span className="p-2 text-label">Create Community Member Account</span>
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            className="d-flex flex-column align-items-center menu-item px-2 pt-4"
                            onClick={() => {
                                history.push(`/instructor/sign-in`);
                            }}
                        >
                            <span className="align-items-center flex-column d-flex">
                                <FontAwesomeIcon icon={faChalkboardTeacher} size="lg" className="cursor-pointer" />
                                <span className="p-2 text-label">Instructor / Faculty Login</span>
                            </span>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                )}
            </div>
        </NavBase>
    );
};

export default SignInHeader;
