import {useElements, useStripe} from "@stripe/react-stripe-js";
import {Formik, FormikProps, FormikValues} from "formik";
import React, {useEffect, useMemo, useState} from "react";
import * as yup from 'yup';
import {ErrorBoundary, FallbackProps} from 'react-error-boundary'
import {Box, Button, CircularProgress, Divider, Grid, Tooltip} from "@mui/material";
import {formatter} from "@/Helpers/formatter.ts";
import PaymentFormPaymentAmount from "@/components/Forms/Fields/PaymentFormPaymentAmount.tsx";
import StripeForm from "@/components/StripeForm.tsx";
import {loadStripe} from "@stripe/stripe-js";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";
import useConfirmMailIn from "@/hooks/useConfirmMailIn.tsx";
import {useHistory} from "react-router-dom";
import {ProgramsProviderContext} from "@/components/Providers/ProgramsProvider.tsx";
import ButtonCollection from "@/components/Forms/Fields/ButtonCollection.tsx";
import {useFeeProvider} from "@/components/Providers/FeesProvider.tsx";

export const stripePromise = loadStripe(import.meta.env.VITE_APP_STRIPE_KEY as string);

stripePromise.catch(e => console.error('stripePromise', e));

type Props = {
    cost: number;
    formikRef: React.Ref<FormikProps<any>>;
};

const ErrorFallback = ({error, resetErrorBoundary}: FallbackProps) => {
    return (
        <div role="alert">
            <p>There was a issue loading the credit card payments</p>
            <pre>{error.message}</pre>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    )
};

export const buildPayAmount = (paymentType: string, cost: number, partialPaymentAmount: string): number => {
    return paymentType === 'partial' ? parseFloat(partialPaymentAmount) : cost;
}

const CartPaymentForm = ({cost, formikRef}: Props) => {
    const {cart, refreshCart, getOriginalCartTotal, cartPaymentSuccess} = useCartContext();
    const {assessedFees} = useFeeProvider();
    const stripe = useStripe();
    const elements = useElements();
    const confirmMailIn = useConfirmMailIn();

    const [submitStripeValues, setSubmitStripeValues] = useState<FormikValues | null>(null);
    const history = useHistory();
    const [programsState, performRegistrationAction] = React.useContext(ProgramsProviderContext);
    const [isProcessing, setIsProcessing] = useState(false);
    const [stripeErrorMessage, setStripeErrorMessage] = useState('');

    const handleMailInSubmit = async (payNowAmount: number) => {
        const courseSectionIds = cart?.cartItems.map(item => item.courseSectionId);

        if (courseSectionIds && performRegistrationAction && cart) {
            const confirmResponse = await confirmMailIn(courseSectionIds, cart.id);

            if (confirmResponse?.ok) {
                const responseJson = await confirmResponse.json();
                if (responseJson) {
                    for (const csId of courseSectionIds) {
                        localStorage.removeItem('registration-course-' + csId);
                    }

                    await refreshCart();
                    await cartPaymentSuccess(`Mail In payment of $${payNowAmount.toString()} submitted.`);

                    history.push('/my-registrations');
                    setIsProcessing(false);
                }
            }
        }
    };

    const allowPartialPayment = useMemo(() => {
        if (!cart?.cartItems) {
            return false;
        }

        return cart.cartItems.some(item => {
            if (item.itemType === 'registration') {
                const course = programsState.programs
                    .flatMap(program => program.courses)
                    .find(course => course.courseSelectionUUID === item.courseSectionId);

                if (course && course.programAllowPartial) {
                    return true
                }
            }

            if (item.itemType === 'fee') {
                const assessedFee = assessedFees
                    ?.find(assessedFee => assessedFee.id === item.assessedFeeId)

                if (assessedFee?.fee.allowPartialPayment) {
                    return true
                }
            }

            return false;
        });
    }, [programsState, cart]);

    const minimumPayment = useMemo(() => {
        if (!cart?.cartItems) {
            return 0;
        }

        return cart.cartItems.reduce((minimum, item) => {
            if (item.itemType === 'registration') {
                const course = programsState.programs
                    .flatMap(program => program.courses)
                    .find(course => course.courseSelectionUUID === item.courseSectionId);

                if (course) {
                    return minimum + (course.programAllowPartial ? course.cost * 0.1 : course.cost);
                }
            }

            if (item.itemType === 'fee') {
                const assessedFee = assessedFees
                    ?.find(assessedFee => assessedFee.id === item.assessedFeeId)

                if (assessedFee) {
                    return minimum + (assessedFee.fee.allowPartialPayment ? assessedFee.assessedCost * 0.1 : assessedFee.assessedCost);
                }
            }

            return minimum;
        }, 0);
    }, [programsState, cart]);

    const initialValues = {
        paymentMethod: '',
        paymentType: allowPartialPayment ? '' : 'full',
        partialPaymentAmount: '',
    }

    const schema = useMemo(() => yup.object({
        paymentMethod: yup.string().required('Payment Method is a required field.'),
        paymentType: yup.string().required('Payment Type is a required field.'),
        partialPaymentAmount: yup.number()
            .when('paymentType', {
                is: 'partial',
                then: yup.number()
                    .min(minimumPayment, `Payment amount must be at least 10% of the total cost and include full payment for courses that require it. (${formatter.format(minimumPayment)}).`)
                    .max(cost, `Max payment of (${formatter.format(cost)}).`)
                    .required(`Payment amount must be at least 10% of the total cost and include full payment for courses that require it/ (${formatter.format(minimumPayment)}).`)
                    .test(
                        "maxDigitsAfterDecimal",
                        "Payment must have 2 digits after decimal or less",
                        (number) => number ? /^\d+(\.\d{1,2})?$/.test(number.toString()) : false
                    ),
                otherwise: yup.number().notRequired(),
            }),
    }), [cost, minimumPayment]);

    useEffect(() => {
        if (stripeErrorMessage !== '') {
            setIsProcessing(false);
            setSubmitStripeValues(null);
        }
    }, [stripeErrorMessage]);

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={async (values) => {
                    const payNowAmount = buildPayAmount(
                        values.paymentType,
                        cost,
                        values.partialPaymentAmount
                    );

                    setStripeErrorMessage('');
                    setIsProcessing(true);
                    if (values.paymentMethod === 'stripe') {
                        setSubmitStripeValues(values)
                    } else if (values.paymentMethod === 'mail') {
                        await handleMailInSubmit(payNowAmount);
                    }
                }}
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize={true}
                innerRef={formikRef}
            >
                {(props) => {
                    const formValues = props.values;
                    const submitForm = props.submitForm;
                    const payNowAmount = buildPayAmount(
                        formValues.paymentType,
                        cost,
                        formValues.partialPaymentAmount
                    );

                    const isPayButtonDisabled = !formValues.paymentType || (formValues.paymentType === 'partial' && (!formValues.partialPaymentAmount) || isNaN(+formValues.partialPaymentAmount));
                    const hasFormErrors = Object.keys(props.errors).length > 0;

                    return <Box>
                        <Grid container>
                            {allowPartialPayment && <Grid item xs={12}>
                                <Box sx={{
                                    fontWeight: 800,
                                    fontSize: '120%',
                                    pt: 2,
                                    pb: 1,
                                    color: '#000',
                                }}>
                                    Payment Options
                                </Box>
                            </Grid>}
                            {allowPartialPayment && <Grid item xs={12}>
                                <ButtonCollection
                                    name="paymentType"
                                    options={[
                                        {
                                            value: 'full',
                                            label: `Pay ${formatter.format(cost)} in Full`
                                        },
                                        {
                                            value: 'partial',
                                            label: 'Partial Payment',
                                            disabled: cost < .5 || cost < (.1 * getOriginalCartTotal())
                                        }
                                    ]}
                                    sx={{
                                        my: 1,
                                    }}
                                />
                            </Grid>}
                            {formValues.paymentType === 'partial' && <Grid item xs={12}>
                                <PaymentFormPaymentAmount name="partialPaymentAmount"/>
                                <Grid item xs={12}>* Partial payment amount will be evenly distributed to each
                                    registration</Grid>
                            </Grid>}
                            <Grid item xs={12}>
                                {allowPartialPayment && <Divider sx={{mt: 2}}/>}
                                <Box
                                    sx={{
                                        fontWeight: 800,
                                        fontSize: '120%',
                                        pt: 2,
                                        pb: 1,
                                        color: '#000',
                                    }}
                                >
                                    Payment Detail
                                </Box>
                                <ButtonCollection
                                    name="paymentMethod"
                                    options={[
                                        {
                                            value: 'mail',
                                            label: 'Mail a Paper Check',
                                            disabled: !formValues.paymentType || formValues.paymentType === 'partial',
                                            hidden: formValues.paymentType === 'partial'
                                        },
                                        {
                                            value: 'stripe',
                                            label: 'Pay Online',
                                            disabled: !formValues.paymentType || cost < .5,
                                            selected: formValues.paymentType === 'partial'
                                        }
                                    ]}
                                    sx={{
                                        display: formValues.paymentType === 'partial' ? 'none' : 'block',
                                        mt: 1,
                                        mb: 2,
                                    }}
                                />
                            </Grid>
                            {formValues.paymentMethod === 'mail' && <Grid item xs={12}>
                                <Box>
                                    <div>Submit all checks to:</div>
                                    <div>Glenbrook High School District 225</div>
                                    <div>ATTN: Enrollment Specialist</div>
                                    <div>3801 W Lake Ave.</div>
                                    <div>Glenview, IL 60026</div>
                                    <div>BE SURE TO INDICATE THE PARTICIPANT'S NAME <span
                                        style={{textDecoration: 'underline'}}>AND</span> ID NUMBER
                                    </div>
                                </Box>
                            </Grid>}
                            {(formValues.paymentMethod === 'stripe' || formValues.paymentType === 'partial') &&
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        {
                                            (stripe === null || elements === null) ?
                                                <p>Loading credit card payment form. If this message persists, try
                                                    reloading the page or clearing your browser cache.</p> :
                                                <ErrorBoundary
                                                    FallbackComponent={ErrorFallback}
                                                    onReset={() => {
                                                        formValues.paymentMethod = '';
                                                    }}
                                                >
                                                    {cart && <StripeForm
                                                        cartId={cart.id}
                                                        courseSectionId={null}
                                                        submitValues={submitStripeValues}
                                                        payNowAmount={payNowAmount}
                                                        setIsProcessing={setIsProcessing}
                                                        stripeErrorMessage={stripeErrorMessage}
                                                        setStripeErrorMessage={setStripeErrorMessage}
                                                    />}
                                                </ErrorBoundary>
                                        }
                                    </Grid>
                                </Grid>}
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                mt: 2,
                            }}
                        >
                            {isProcessing && <Button
                                disabled={true}
                                sx={{
                                    backgroundColor: '#631d79',
                                    color: '#fff',
                                    borderRadius: '8px',
                                    fontWeight: 800,
                                    p: 2,
                                    mb: 2,
                                    '&:hover': {
                                        backgroundColor: '#631d79',
                                    }
                                }}
                            >
                                <CircularProgress
                                    size='1rem'
                                    sx={{
                                        mr: 1,
                                        color: '#fff',
                                    }}
                                />
                                <Box sx={{color: '#fff'}}>{`Processing Payment`}</Box>
                            </Button>}

                            {!isProcessing && isPayButtonDisabled &&
                                <Tooltip title={'Please fix the validation issues above'}>
                                    <Button
                                        variant='contained'
                                        onClick={submitForm}
                                        disabled={isPayButtonDisabled}
                                        sx={{
                                            backgroundColor: '#631d79',
                                            color: '#fff',
                                            p: 2,
                                            borderRadius: '8px',
                                            fontWeight: 800,
                                            mb: 2,
                                            '&:hover': {
                                                backgroundColor: '#631d79',
                                            }
                                        }}
                                    >
                                        {`Pay ${isNaN(payNowAmount) ? '' : formatter.format(payNowAmount)} Now`}
                                    </Button>
                                </Tooltip>}

                            {!isProcessing && !isPayButtonDisabled && (hasFormErrors || stripeErrorMessage !== '') &&
                                <Tooltip title={'Please fix the validation issues above'}>
                                    <Button
                                        variant='contained'
                                        onClick={submitForm}
                                        sx={{
                                            backgroundColor: '#631d79',
                                            color: '#fff',
                                            p: 2,
                                            borderRadius: '8px',
                                            fontWeight: 800,
                                            mb: 2,
                                            '&:hover': {
                                                backgroundColor: '#631d79',
                                            }
                                        }}
                                    >
                                        {`Pay ${isNaN(payNowAmount) ? '' : formatter.format(payNowAmount)} Now`}
                                    </Button>
                                </Tooltip>}

                            {!isProcessing && !isPayButtonDisabled && !(hasFormErrors || stripeErrorMessage !== '') &&
                                <Button
                                    variant='contained'
                                    onClick={submitForm}
                                    sx={{
                                        backgroundColor: '#631d79',
                                        color: '#fff',
                                        p: 2,
                                        borderRadius: '8px',
                                        fontWeight: 800,
                                        mb: 2,
                                        '&:hover': {
                                            backgroundColor: '#631d79',
                                        }
                                    }}
                                >
                                    {`Pay ${isNaN(payNowAmount) ? '' : formatter.format(payNowAmount)} Now`}
                                </Button>}
                        </Grid>
                    </Box>
                }}
            </Formik>
        </>);
}

export default CartPaymentForm;
