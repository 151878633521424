import LockIcon from '@mui/icons-material/Lock';
import PaidIcon from '@mui/icons-material/Paid';
import BlockIcon from "@mui/icons-material/Block";
import * as React from 'react';
import {useContext, useState} from 'react';
import {Box, Button, Card, Grid, Stack, Tooltip, Typography} from "@mui/material";
import {Fee} from "@/types/Fee.ts";
import ViewMoreModalLink from "@/components/Fees/ViewMoreModalLink.tsx";
import {apiEndpoint, jwtContext} from "@/components/Providers/JWTProvider.tsx";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";
import {useFeeProvider} from "@/components/Providers/FeesProvider.tsx";
import {getFeeDisplayIconType} from "@/Helpers/getFeeDisplayIconType.ts";
import CartWithCheckbox from "@/components/Cart/CartWithCheckbox.tsx";
import PartialPaid from "@/components/Cart/PartialPaid.tsx";
import {buildFeePrice} from "@/Helpers/buildFeePrice.ts";
import {formatter} from "@/Helpers/formatter.ts";
import FeeQuestionModal from "@/components/Modal/FeeQuestionModal.tsx";

type Props = {
    fee: Fee;
};

const OptionalFeeListItem = ({fee} : Props) : React.ReactElement => {
    const user = useContext(jwtContext)
    const {cart, refreshCart} = useCartContext();
    const {refreshFees, purchaseHistory} = useFeeProvider();
    const [showCourseQuestionModal, setShowCourseQuestionModal] = useState<boolean>(false);

    const isInCart = cart?.cartItems
        .some(item => item.itemType === 'fee' && item.feeId === fee.id) ?? false;

    const displayIcon = getFeeDisplayIconType(isInCart, fee, user, purchaseHistory);

    const handleAddToCart = async () => {
        const url = new URL('/v1/fees/add-to-cart', apiEndpoint);

        let init : RequestInit = {
            method: 'PUT',
            signal: AbortSignal.timeout(10000)
        };

        init.body = JSON.stringify({
            feeId: fee.id,
        });

        const response = await user?.apiFetch(url.toString(), init);

        if (response && response.ok) {
            await refreshFees();
            await refreshCart();
        } else {
            // setErrorMessage('An unknown error has occurred.');
            console.info('An unknown error has occurred.');
        }
    }

    const handleFeeQuestionsModal = async () => {
        if (fee.courseQuestions.length) {
            setShowCourseQuestionModal(true);
            return;
        }

        await handleAddToCart();
    }

    return <Card
        sx={{
            '&:nth-of-type(odd)': {
                backgroundColor: "#eee",
            },
            '&:nth-of-type(even)': {
                backgroundColor: "white",
            },
            p: 1,
            mb: 1,
            border: '1px solid #ccc',
        }}
    >
        {user && <Grid
            container
            sx={{
                mb: 2,
            }}
        >
            <Grid
                item
                xs={12}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    fontWeight: 800,
                    fontSize: '1.5em',
                }}>
                <Box sx={{fontWeight: 800}}>{fee.name}</Box>
                <Stack sx={{flexDirection: 'row', justifyContent: 'center'}}>
                    <Box sx={{display: 'inline'}}>{formatter.format(buildFeePrice(fee, user, purchaseHistory))}</Box>
                </Stack>
            </Grid>
            <Grid item xs={12} md={9}>
                <Box sx={{display: 'inline', mr: 1, pr: 1}}>
                    {fee.description.substring(0, 120).split('¶').map((line) => <Box style={{display: 'inherit'}}>{line}</Box>)}
                </Box>
                <Box sx={{display: 'inline'}}>
                    <ViewMoreModalLink fee={fee}/>
                </Box>
            </Grid>
          <Grid item xs={12} md={3} sx={{textAlign: 'right', justifyContent: 'end'}}>
              {displayIcon === 'CartIcon' && (
                  <Button
                      variant="contained"
                      onClick={handleFeeQuestionsModal}
                      sx={{
                          backgroundColor: '#631d79',
                          textTransform: 'none',
                          "&:hover": {
                              backgroundColor: "#F0F0F0",
                              color: '#631d79',
                          },
                          whiteSpace: 'nowrap',
                          px: 1,
                          width: '100%'
                      }}
                  >
                      Add to cart
                  </Button>
              )}
              {displayIcon === 'InCart' && <Tooltip
                  title='This fee is in the cart.'
              >
                <CartWithCheckbox/>
              </Tooltip>}
              {displayIcon === 'PartialIcon' && <Tooltip
                  title='This Fee has been partially paid..'
              >
                <PartialPaid handleAddToCart={handleAddToCart} fee={fee}/>
              </Tooltip>}
              {displayIcon === 'PaidIcon' && <Tooltip
                  title='This Fee has been purchased.'
              >
                <PaidIcon/>
              </Tooltip>}
              {displayIcon === 'BlockIcon' && <Tooltip
                  title='This Fee has been purchased the maximum allowable times.'
              >
                <BlockIcon/>
              </Tooltip>}
              {displayIcon === 'FeeCriteriaErrorIcon' && <Tooltip
                  title={fee.feeCriteriaError}
              >
                <BlockIcon/>
              </Tooltip>}
          </Grid>
            <Grid item xs={12} md={9} sx={{display: 'flex', justifyContent: 'left', alignItems: 'left', mb: 1}}>
                <Tooltip title="Requires Payment in Full">
                    <LockIcon/>
                </Tooltip>
                <Typography
                    variant='body2'
                    sx={{
                        textAlign:  'left',
                    }}
                >
                    Requires Payment in Full
                </Typography>
            </Grid>

        </Grid>}
        {fee.courseQuestions && <FeeQuestionModal
            show={showCourseQuestionModal}
            handleClose={() => setShowCourseQuestionModal(false)}
            fee={fee}
            performActionBasedOnCourseStatus={handleAddToCart ?? (async () => {})}
        />}
    </Card>;
}

export default OptionalFeeListItem;
