import {TextField, TextFieldProps} from '@mui/material';
import {Control, Controller, FieldPath, FieldValues, useController} from 'react-hook-form';
import React, {useState} from "react";
import {useMaskito} from '@maskito/react';
import {MaskitoOptions} from "@maskito/core";

export type RhNumericTextFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<TextFieldProps, 'error' | 'onChange' | 'onBlur' | 'value' | 'inputRef'> & {
    control: Control<TFieldValues>;
    name: TName;
};

const digitsOnlyMask: MaskitoOptions = {
    mask: /^\d+$/,
};

const RhfNumericTextField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
    {
        control,
        name,
        label,
        ...props
    }: RhNumericTextFieldProps<TFieldValues, TName>
): React.ReactElement => {
    const inputRef = useMaskito({options: digitsOnlyMask});
    const {field, fieldState} = useController({control, name});
    const [focused, setFocused] = useState(false);
    const fieldHasValue = field.value !== undefined && field.value !== '';

    return (
        <Controller
            render={() => <TextField
                inputRef={inputRef}
                name={name}
                label={label}
                onBlur={() => {
                    setFocused(false);
                    field.onBlur()
                }}
                onFocus={() => {
                    setFocused(true);
                }}
                onInput={field.onChange}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message ?? ''}

                {...props}

                sx={{
                    "& .MuiInputLabel-outlined": {
                        ml: fieldHasValue ? 0 : 2,
                        "&.Mui-focused": {
                            ml: 0,
                        },
                    },
                    ...props.sx
                }}

                InputLabelProps={{
                    shrink: fieldHasValue || focused,
                    ...props.InputLabelProps
                }}
                value={field.value}
            />}
            name={name}
            control={control}
        />
    );
};

export default RhfNumericTextField;
