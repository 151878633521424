import NavBase from './NavBase';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import InfoIcon from '@mui/icons-material/Info';
import LogoutIcon from '@mui/icons-material/Logout';
import {Nav, Navbar} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {jwtInstructorContext, signOutInstructor} from "../Providers/JWTProvider";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import InstructorInstructionsModal from "@/components/Modal/InstructorInstructionsModal.tsx";
import {useContext, useState} from "react";

type Props = {
    showNav ?: boolean;
}

const InstructorHeader = ({showNav} : Props) => {
    let history = useHistory();
    const [showInstructorInstructionsModal, setShowInstructorInstructionsModal] = useState(false);
    const {user} = useContext(jwtInstructorContext);

    return (
        <NavBase>
            <div className="text-right d-flex flex-row">
                <Navbar.Toggle aria-controls="chris-nav" />
                {showNav && (
                    <Navbar.Collapse id="glenbrook-nav" className="">
                        <Nav className="mr-auto">
                            {user?.role.includes('fee_admin') && <Nav.Link
                                className="d-flex flex-column align-items-center menu-item px-2 pt-3"
                                onClick={() => {
                                    history.push(`/instructor/fee-proposals`);
                                }}
                            >
                                <span className="align-items-center flex-column d-flex">
                                    <FactCheckIcon/>
                                    <span className="p-2 text-label">Fee Requests</span>
                                </span>
                            </Nav.Link>}
                            {user?.role.includes('instructor') && <Nav.Link
                                className="d-flex flex-column align-items-center menu-item px-2 pt-3"
                                onClick={() => {
                                    history.push(`/instructor`);
                                }}
                            >
                                <span className="align-items-center flex-column d-flex">
                                    <ContactPageIcon/>
                                    <span className="p-2 text-label">My Activities</span>
                                </span>
                            </Nav.Link>}
                            <Nav.Link
                                className="d-flex flex-column align-items-center menu-item mx-2 pt-3 px-3 menu-item-border-right"
                                onClick={() => {
                                    setShowInstructorInstructionsModal(true);
                                }}
                            >
                                <span className="align-items-center flex-column d-flex">
                                    <InfoIcon/>
                                    <span className="p-2 text-label">Instructions</span>
                                </span>
                            </Nav.Link>
                            <Nav.Link
                                className="d-flex flex-column align-items-center menu-item pt-3 px-2"
                                onClick={() => {
                                    signOutInstructor();
                                    window.location.reload();
                                }}
                            >
                        <span className="align-items-center flex-column d-flex">
                            <LogoutIcon/>
                            <span className="p-2 text-label">Sign Out</span>
                        </span>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                )}
            </div>
            <InstructorInstructionsModal
                show={showInstructorInstructionsModal}
                handleClose={() => setShowInstructorInstructionsModal(false)}
            />
        </NavBase>
    );
};

export default InstructorHeader;
